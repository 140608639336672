import { pluralizeNoun } from '../../shared/utils';
import { HomeRoutes, ROUTES, ROUTES_TABS_MAP } from '../../types/routes';
import { defaultTitleConfig, setTitleConfig } from '../appReducer';
import { AppThunk } from '../store';

const updateHostTitleConfig = (): AppThunk => async (dispatch, getState) => {
  const { hostMetadata, mangoTitle, mangoesTitle } = getState().app;

  const getRouteTitle = (routeKey: HomeRoutes) =>
    hostMetadata?.routingConfig?.routes?.find(
      (route) => route.key === ROUTES_TABS_MAP[routeKey]?.config?.key,
    )?.title;

  const getTitle = (
    payloadTitle: string,
    routeTitle: string,
    defaultTitle: string,
  ) => (payloadTitle || routeTitle || defaultTitle).toLowerCase();

  const getPluralTitle = (
    payloadTitle: string,
    routeTitle: string,
    defaultTitle: string,
  ) =>
    (
      payloadTitle ||
      pluralizeNoun(getTitle(payloadTitle, routeTitle, defaultTitle)) ||
      defaultTitle
    ).toLowerCase();

  const payloadTitleConfig = hostMetadata?.titleConfig || defaultTitleConfig;
  const { course, courses, message, messages, workshop, workshops } =
    defaultTitleConfig;

  const newTitleConfig = {
    mango: mangoTitle,
    mangoes: mangoesTitle,
    course: getTitle(
      payloadTitleConfig?.course,
      getRouteTitle(ROUTES.COURSES) || course,
      course,
    ),
    courses: getPluralTitle(
      payloadTitleConfig?.courses,
      getRouteTitle(ROUTES.COURSES) || courses,
      courses,
    ),
    message: getTitle(
      payloadTitleConfig?.message,
      getRouteTitle(ROUTES.MESSAGES) || message,
      message,
    ),
    messages: getPluralTitle(
      payloadTitleConfig?.messages,
      getRouteTitle(ROUTES.MESSAGES) || messages,
      messages,
    ),
    workshop: getTitle(
      payloadTitleConfig?.workshop,
      getRouteTitle(ROUTES.VIDEO_CALL) || workshop,
      workshop,
    ),
    workshops: getPluralTitle(
      payloadTitleConfig?.workshops,
      getRouteTitle(ROUTES.VIDEO_CALL) || workshops,
      workshops,
    ),
  };

  dispatch(setTitleConfig(newTitleConfig));
};

export { updateHostTitleConfig };
