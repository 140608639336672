import './styles.scss';

import React, { useState } from 'react';

import { Avatar, Typography } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';
import TMIcon from 'tm-icons-library';

import Assets from '../../../assets';
import BottomSheetTile from '../../../components/BottomSheetTile/BottomSheetTile';
import CustomReadMoreText from '../../../components/CustomReadMoreText/CustomReadMoreText';
import ImageAttachment from '../../../components/ImageAttachment/ImageAttachment';
import MenuButton from '../../../components/MenuButton/MenuButton';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { DELETED_USER } from '../../../shared/data';
import { timeAgo } from '../../../shared/utils';

interface Props {
  qnaId: string;
  attachments?: string[];
  description: string;
  createdAt: Date;
  imgUrl?: string;
  userName: string;
  isCreator: boolean;
  containerStyle?: React.CSSProperties;
  showMenu?: boolean;
  onRemoveItem?: () => void;
}

const QNAReplyItem: React.FC<Props> = ({
  qnaId,
  attachments,
  description,
  createdAt,
  imgUrl,
  userName,
  isCreator,
  containerStyle = {},
  showMenu = false,
  onRemoveItem = () => {},
}) => {
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  return (
    <>
      <div
        className="qna__reply__item"
        id={`qna__reply__item-${qnaId}`}
        style={containerStyle}>
        <Avatar size={36} src={imgUrl || Assets.images.defaultProfilePic} />

        <div style={{ marginLeft: 12, width: '100%' }}>
          <div className="qna__reply__item__header">
            <Typography.Title
              className="qna__reply__item__header__title"
              level={4}>
              {userName || DELETED_USER}{' '}
              {isCreator ? (
                <Typography.Text className="qna__reply__item__header__title__creator">
                  INSTRUCTOR
                </Typography.Text>
              ) : null}
              <Typography.Text className="qna__reply__item__header__title__time">
                • {timeAgo(createdAt)}
              </Typography.Text>
            </Typography.Title>

            {showMenu ? (
              <div className="qna__reply__item__header__menu__wrapper">
                <MenuButton
                  className="qna__reply__item__header__menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowBottomSheet(true);
                  }}
                />
              </div>
            ) : null}
          </div>

          {description ? (
            <div
              style={{
                marginTop: 4,
              }}>
              <CustomReadMoreText
                text={description}
                truncatedLength={200}
                className="qna__reply__item__description"
              />
            </div>
          ) : null}

          {attachments && attachments?.length > 0 ? (
            <ImageAttachment
              containerStyle={{
                marginTop: 12,
              }}
              images={attachments}
              maxImagesAllowed={2}
            />
          ) : null}
        </div>
      </div>

      <BottomSheet
        open={showBottomSheet}
        onDismiss={() => setShowBottomSheet(false)}>
        <BottomSheetTile
          key={1}
          icon={<TMIcon name="trash" size={20} color="currentColor" />}
          warning
          alignIcon="flex-start"
          title="Delete comment"
          description="This comment will be removed from this thread"
          onClick={() => {
            setShowBottomSheet(false);
            setIsDialogVisible(true);
          }}
        />
      </BottomSheet>

      <ConfirmationModal
        open={isDialogVisible}
        message="Do you really want to delete this comment?"
        title="Delete Comment"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
        handleOk={() => {
          setIsDialogVisible(false);
          onRemoveItem();
        }}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
      />
    </>
  );
};

export default React.memo(QNAReplyItem);
