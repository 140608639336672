import React, { useMemo } from 'react';

import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import { SocketProvider } from './context/SocketProvider';
import CourseWrapper from './layouts/Course/CourseWrapper';
import Login from './layouts/Login/Login';
import AccessDenied from './screens/AccessDenied/AccessDenied';
import CourseDetails from './screens/CourseDetails/CourseDetails';
import CourseOverview from './screens/CourseOverview/CourseOverview';
import Courses from './screens/Courses/Courses';
import CreatePoll from './screens/CreatePost/CreatePoll';
import CreatePost from './screens/CreatePost/CreatePost';
import CreatorProfile from './screens/CreatorProfile/CreatorProfile';
import CustomAppsWrapper from './screens/CustomApps/CustomAppsWrapper';
import Feed from './screens/Feed/Feed';
import LoginBox from './screens/Login/LoginBoxv2';
import SignupBox from './screens/Login/SignupBox';
import SignupCreatorBox from './screens/Login/SignupCreatorBox';
import VerifyEmailOrPhone from './screens/Login/VerifyEmailOrPhone';
import VerifyOTPBox from './screens/Login/VerifyOTPBox';
import Messages from './screens/Messages/Messages';
import DMChatScreen from './screens/Messages/screens/DMChatScreen/DMChatScreen';
import MangoChatScreen from './screens/Messages/screens/MangoChatScreen/MangoChatScreen';
import NewMessage from './screens/Messages/screens/NewMessage/NewMessage';
import NotFound from './screens/NotFound/NotFound';
import Notifications from './screens/Notifications/Notifications';
import PlatformCrashed from './screens/PlatformCrashed/PlatformCrashed';
import PostDetailsScreen from './screens/PostDetails/PostDetails';
import Profile from './screens/Profile/Profile';
import AccountSettings from './screens/Profile/screens/AccountSettings/AccountSettings';
import ChangeEmail from './screens/Profile/screens/AccountSettings/ChangeEmail';
import ChangeNumber from './screens/Profile/screens/AccountSettings/ChangeNumber';
import DeleteAccount from './screens/Profile/screens/DeleteAccount/DeleteAccount';
import HelpSupport from './screens/Profile/screens/HelpSupport';
import Leaderboard from './screens/Profile/screens/Leaderboard/Leaderboard';
import NotificationSettings from './screens/Profile/screens/NotificationSettings/NotificationSettings';
import AboutYou from './screens/Profile/screens/PersonalInfo/AboutYou';
import PersonalInfo from './screens/Profile/screens/PersonalInfo/PersonalInfo';
import PointsLedger from './screens/Profile/screens/PointsLedger/PointsLedger';
import Subscriptions from './screens/Profile/screens/Subscriptions/Subscriptions';
import VerifyEmailScreen from './screens/Profile/screens/VerifyEmail/VerifyEmail';
import Storefront from './screens/Storefront/Storefront';
import MangoesPurchased from './screens/UserProfile/components/MangoesPurchased';
import UserProfile from './screens/UserProfile/UserProfile';
import Workshops from './screens/Workshops/Workshops';
import { useAppSelector } from './shared/hooks';
import { HomeRoutes, ROUTES, UAM_PATH_ACCESS_MAP } from './types/routes';
import { UAMPermissions } from './types/userTypes';

const Router: React.FC = () => {
  const {
    customRouter: { homeRoutes, initialRoutes },
  } = useAppSelector((state) => state.app);
  const { type: userType, userAccessPermissions } = useAppSelector(
    (state) => state.user,
  );

  const initialRoute: ROUTES = useMemo(() => {
    const defaultRoute: HomeRoutes =
      initialRoutes?.default?.route || ROUTES.FEED;

    if (!userType) {
      return defaultRoute;
    }

    // redirection for UAM
    // Steps:
    // check if feed is present in homeRoutes, if yes, then feed is initial route.
    // check if default route present, if yes, then check access to default route, if yes, then default route is initial route.
    // check access to any other route in homeRoutes, if yes, then that route is initial route.
    // no access to any of the above, then access denied route is the initial route
    if (userType === 'creator_restricted') {
      let routeToOpen: ROUTES = ROUTES.FEED;

      // check if Feed is visible
      const isFeedVisible =
        homeRoutes?.some((route) => route.name === ROUTES.FEED) &&
        userAccessPermissions?.includes(UAMPermissions.FEED_READ);

      if (isFeedVisible) {
        routeToOpen = ROUTES.FEED;
      }

      // check if UAM has access to default route
      const defaultRouteAccess =
        UAM_PATH_ACCESS_MAP?.[defaultRoute as keyof typeof UAM_PATH_ACCESS_MAP];

      if (
        defaultRouteAccess &&
        userAccessPermissions?.includes(defaultRouteAccess)
      ) {
        routeToOpen = defaultRoute;
      } else {
        // check if UAM has access to any visible route
        const accessibleRoute = homeRoutes?.find((route) => {
          const routeAccess =
            UAM_PATH_ACCESS_MAP?.[
              route.config.path as keyof typeof UAM_PATH_ACCESS_MAP
            ];

          return routeAccess && userAccessPermissions?.includes(routeAccess);
        });

        if (accessibleRoute) {
          routeToOpen = accessibleRoute.name;
        }
      }

      return routeToOpen;
    }

    return initialRoutes?.[userType]?.route || defaultRoute;
  }, [homeRoutes, initialRoutes, userAccessPermissions, userType]);

  return (
    <Routes>
      <Route
        path={ROUTES.REFER_N_EARN_SIGNUP}
        element={
          <Login>
            <SignupCreatorBox />
          </Login>
        }
      />

      <Route path={ROUTES.LOGIN} element={<Login />}>
        <Route
          path={ROUTES.LOGIN}
          element={
            <PrivateRoute reverse redirectTo={ROUTES.HOME}>
              <LoginBox />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.VERIFY_OTP}
          element={
            <PrivateRoute reverse redirectTo={ROUTES.HOME}>
              <VerifyOTPBox />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.VERIFY_EMAIL_PHONE}
          element={
            <PrivateRoute reverse redirectTo={ROUTES.HOME}>
              <VerifyEmailOrPhone />
            </PrivateRoute>
          }
        />
        <Route path={ROUTES.REGISTER} element={<SignupBox />} />
        <Route path={ROUTES.SIGNUPCREATOR} element={<SignupCreatorBox />} />
      </Route>

      <Route path={ROUTES.POST_DETAILS} element={<PostDetailsScreen />} />
      <Route path={ROUTES.COURSE_OVERVIEW} element={<CourseOverview />} />
      <Route path={ROUTES.CREATOR_PROFILE} element={<CreatorProfile />} />
      <Route path={ROUTES.CREATOR_PROFILE_SLUG} element={<CreatorProfile />} />
      <Route path={ROUTES.NOTFOUND} element={<NotFound />} />
      <Route path={ROUTES.PLATFORM_CRASHED} element={<PlatformCrashed />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        }>
        <Route path={ROUTES.CREATE_POST} element={<CreatePost />} />
        <Route path={ROUTES.CREATE_POLL} element={<CreatePoll />} />
        <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
        <Route path={ROUTES.PERSONAL_INFO} element={<PersonalInfo />} />
        <Route path={ROUTES.SUBSCRIPTIONS} element={<Subscriptions />} />
        <Route path={ROUTES.ACCOUNT_SETTINGS} element={<AccountSettings />} />
        <Route path={ROUTES.HELP_SUPPORT} element={<HelpSupport />} />
        <Route path={ROUTES.CHANGE_NUMBER} element={<ChangeNumber />} />
        <Route path={ROUTES.CHANGE_EMAIL} element={<ChangeEmail />} />
        <Route path={ROUTES.DELETE_ACCOUNT} element={<DeleteAccount />} />
        <Route path={ROUTES.ABOUT_YOU} element={<AboutYou />} />
        <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmailScreen />} />
        <Route path={ROUTES.LEADERBOARD} element={<Leaderboard />} />
        <Route path={ROUTES.POINTS_LEDGER} element={<PointsLedger />} />
        <Route path={ROUTES.USER_PROFILE} element={<UserProfile />} />
        <Route path={ROUTES.MANGOES_PURCHASED} element={<MangoesPurchased />} />
        <Route
          path={ROUTES.VERIFY_OTP_ACCOUNT}
          element={
            <Login>
              <VerifyOTPBox />
            </Login>
          }
        />
        <Route
          path={ROUTES.NOTIFICATION_SETTINGS}
          element={<NotificationSettings />}
        />

        {/* Home Routes */}

        <Route path={ROUTES.COURSES} element={<CourseWrapper />}>
          <Route path={ROUTES.COURSE_DETAILS} element={<CourseDetails />} />
          <Route path={ROUTES.COURSES} element={<Courses />} />
        </Route>

        <Route path={ROUTES.MESSAGES} element={<SocketProvider />}>
          <Route path={ROUTES.MESSAGES} element={<Messages />} />
          <Route path={ROUTES.NEW_MESSAGE} element={<NewMessage />} />
          <Route path={ROUTES.MESSAGE_ROOM} element={<MangoChatScreen />} />
          <Route path={ROUTES.MESSAGE_DM} element={<DMChatScreen />} />
          <Route
            path={ROUTES.MESSAGE_DM_PARTICIPANT}
            element={<DMChatScreen />}
          />
        </Route>

        <Route path={ROUTES.FEED} element={<Feed />} />
        <Route path={ROUTES.VIDEO_CALL} element={<Workshops />} />
        <Route path={ROUTES.MENU} element={<Profile />} />
        <Route path={ROUTES.CUSTOM_APPS} element={<CustomAppsWrapper />} />
        <Route path={ROUTES.STOREFRONT} element={<Storefront />} />

        <Route path={ROUTES.NO_ACCESS} element={<AccessDenied />} />

        <Route path="*" element={<Navigate to={ROUTES.NOTFOUND} />} />
        <Route path="" element={<Navigate to={initialRoute} />} />
        <Route path="*" element={<Navigate to={ROUTES.NOTFOUND} />} />
      </Route>
    </Routes>
  );
};

export default Router;
