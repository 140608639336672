import * as React from 'react';

import { message, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import CourseAPI from '../../../api/CourseAPI';
import CommentInput from '../../../components/CommentInput/CommentInput';
import Loader from '../../../components/Loader';
import { useTheme } from '../../../context/ThemeProvider';
import showAppError from '../../../shared/error';
import { useAppSelector } from '../../../shared/hooks';
import { scrollToElement } from '../../../shared/utils';
import {
  Creator,
  ICreateCourseCommentRequest,
} from '../../../types/courseTypes';
import { IQNA } from '../../../types/qnaTypes';
import QNAItem from '../QNAItem/QNAItem';
import QNAReplies from '../QNAReplies/QNAReplies';

interface CommentsSectionProps {
  chapterId: string;
  creator: Creator;
}

interface ICommentsState {
  comments: IQNA[];
  hasMore: boolean;
  page: number;
  loading: boolean;
  refreshing: boolean;
}

interface IReplies {
  chapterId: string;
  qna: IQNA;
  creator: Creator;
}

const defaultState: ICommentsState = {
  loading: false,
  page: 1,
  hasMore: true,
  comments: [],
  refreshing: false,
};

const PAGE_LIMIT = 10;

const CourseCommentsSection: React.FC<CommentsSectionProps> = ({
  chapterId,
  creator,
}) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);

  const [commentsState, setCommentsState] =
    React.useState<ICommentsState>(defaultState);
  const [showReplies, setShowReplies] = React.useState<IReplies | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const getCommentsPaginated = React.useCallback(
    async (
      pageCount: number,
      commentsList = commentsState.comments,
      hasMore = commentsState.hasMore,
      loading = commentsState.loading,
      currState = commentsState,
    ) => {
      if (hasMore && !loading) {
        setCommentsState({ ...currState, loading: true });
        try {
          const response = await CourseAPI.getChapterComments(chapterId, {
            page: pageCount,
            limit: PAGE_LIMIT,
            type: 'comment',
          });
          const res = response.data;
          let newState = { ...currState, loading: false, refreshing: false };
          if (response.status === 200) {
            if (res.result && res.result.length) {
              const updatedComments =
                pageCount > 1
                  ? [...(commentsList || []), ...res.result]
                  : [...res.result];
              newState = {
                ...newState,
                comments: updatedComments as IQNA[],
                page: pageCount + 1,
                hasMore: res.result.length === PAGE_LIMIT,
              };
            } else {
              newState = {
                ...newState,
                comments: [...(commentsList || [])],
                hasMore: false,
              };
            }
          }
          setCommentsState({ ...commentsState, ...newState });
        } catch (error: any) {
          setCommentsState({ ...commentsState, loading: false });
        }
      }
    },
    [chapterId, commentsState],
  );

  const loadQNA = React.useCallback(() => {
    const newState: ICommentsState = {
      comments: [],
      hasMore: true,
      page: 1,
      loading: false,
      refreshing: false,
    };
    setCommentsState((prev) => ({
      ...prev,
      comments: [],
      hasMore: true,
      page: 1,
    }));
    getCommentsPaginated(1, [], true, false, newState);
  }, [getCommentsPaginated]);

  const removeQNAItem = async (commentId: string) => {
    const m = message.loading('Deleting comment...');
    try {
      const res = await CourseAPI.deleteComment(commentId);
      if (res.status === 200) {
        message.success('Comment successfully removed!');
        setCommentsState((prev) => ({
          ...prev,
          comments: prev.comments.filter(
            (comments) => comments._id !== commentId,
          ),
        }));
      } else {
        showAppError(res.data);
      }
    } catch (error) {
      showAppError(error);
    } finally {
      m();
    }
  };

  const onSend = async (text: string, _?: File[], onSuccess?: () => void) => {
    const comment = (text || '').trim();

    if (!comment) {
      return;
    }

    // setIsInputBoxVisible(false);
    setIsSaving(true);

    const reqBody: ICreateCourseCommentRequest = {
      description: comment,
      type: 'comment',
    };

    try {
      const resp = await CourseAPI.createComment(chapterId, reqBody);
      if (resp.status === 200) {
        // inputRef.current?.clear();
        setCommentsState((prev) => ({
          ...prev,
          comments: [resp.data.result, ...prev.comments] as IQNA[],
        }));
        onSuccess?.();

        setTimeout(() => {
          scrollToElement({
            id: `qna__item-${resp.data.result._id}`,
          });
        }, 500);
      } else {
        showAppError(resp.data);
      }
    } catch (err) {
      showAppError(err);
    } finally {
      setIsSaving(false);
    }
  };

  React.useEffect(() => {
    loadQNA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterId]);

  const footer = React.useMemo(() => {
    if (commentsState.loading || commentsState.hasMore)
      return (
        <Loader
          style={{ width: '100%', textAlign: 'center', margin: '20px 0' }}
        />
      );
    if (commentsState.comments.length === 0)
      return (
        <Typography.Paragraph
          style={{
            width: '100%',
            textAlign: 'center',
            margin: '45px 0',
          }}>
          Oops! No comments posted!
        </Typography.Paragraph>
      );
    return (
      <Typography.Paragraph
        style={{
          width: '100%',
          textAlign: 'center',
          margin: '20px 0',
        }}>
        That’s it! No more comments to show.
      </Typography.Paragraph>
    );
  }, [
    commentsState.hasMore,
    commentsState.loading,
    commentsState.comments.length,
  ]);

  const header = React.useMemo(
    () => (
      <div>
        <Typography.Title level={4} className="chapter__section__title">
          Comments
        </Typography.Title>
        <div className="qna__section__header commentInputBox">
          <CommentInput
            className="commentInputBox__input-item"
            onComment={onSend}
            isSending={isSaving}
            hideMention
          />
        </div>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSaving],
  );

  return (
    <>
      {header}

      {commentsState.comments.length > 0 ? (
        <EasyVirtualizedScroller
          useParentScrollElement
          hasMore={commentsState.hasMore}
          onLoadMore={() =>
            getCommentsPaginated(commentsState.page, commentsState.comments)
          }>
          {commentsState.comments.map((item) => (
            <QNAItem
              key={item._id}
              type="comment"
              qnaId={item._id}
              question={item.title}
              description={item.description}
              createdAt={item.createdAt}
              imgUrl={item.imgUrl}
              userName={item.userName}
              attachments={item.attachments}
              replyCount={item.replyCount}
              containerStyle={{
                borderBottom: `1px solid ${colors.BORDER}`,
              }}
              showMenu={
                userDetails.id === item.userId ||
                userDetails.id === creator._id ||
                userDetails.type === 'creator_restricted'
              }
              showReply
              isAnswered={item.answered}
              onClick={() => {
                setShowReplies({
                  chapterId,
                  qna: item as IQNA,
                  creator,
                });
              }}
              onRemoveItem={() => {
                removeQNAItem(item._id);
              }}
            />
          ))}
        </EasyVirtualizedScroller>
      ) : null}

      {footer}

      {showReplies && (
        <QNAReplies
          type="comment"
          showModal={Boolean(showReplies)}
          closeModal={() => setShowReplies(null)}
          {...showReplies}
          onRefresh={() => loadQNA()}
        />
      )}
    </>
  );
};

export default React.memo(CourseCommentsSection);
