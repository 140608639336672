import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Alert, Button, Form, Input, message, Space } from 'antd';
import ReactFlagsSelect from 'react-flags-select';
import { useLocation } from 'react-router-dom';

import API from '../../api/index';
import UserAPI from '../../api/UserAPI';
import Assets from '../../assets';
import tmlogo from '../../assets/svg/tmlogo.svg';
import CopyrightContent from '../../components/CopyrightContent';
import Loader from '../../components/Loader';
import { useAppProvider } from '../../context/AppProvider';
import { COUNTRY_FLAGS_MAP } from '../../shared/constants';
import showAppError from '../../shared/error';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { emailRegex, generateDigitsRegex, rgxNums } from '../../shared/utils';
import { ROUTES } from '../../types/routes';
import LoginHeader from './LoginHeader';

const VerifyEmailOrPhone: React.FC = () => {
  const navigate = useAppNavigate();
  const location = useLocation();

  const {
    hostMetadata: { logo, customSupportLink },
    isTagMango,
  } = useAppSelector((state) => state.app);

  const { handleLogout } = useAppProvider();

  const [form] = Form.useForm();

  const [mode, setMode] = useState<'phone' | 'email'>('phone');
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState<string>('IN');
  const [isLoadingCountry, setIsLoadingCountry] = useState(false);

  const fetchCountryThroughIP = async () => {
    setIsLoadingCountry(true);
    try {
      const response = await API.getCurrentCountry();
      if (response.data.code === 0) {
        const data = response?.data?.result;
        const fetchedCountryCode = JSON.parse(data as string)?.countryCode;
        if (fetchedCountryCode === 'IN') {
          setMode('phone');
        }
        setCountryCode(fetchedCountryCode);
      }
    } catch (error: any) {
      console.error('Error fetching country through IP', error);
    } finally {
      setIsLoadingCountry(false);
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const isIndia = countryCode === 'IN';

    const minDigits = isIndia ? 10 : 7;
    const maxDigits = isIndia ? 10 : 15;
    const maxDigitsRegex = generateDigitsRegex(maxDigits);
    const validDigitsRegex = generateDigitsRegex(minDigits);

    // remove spaces from phone number
    const phoneNumberWithoutSpaces = phoneNumber
      ? phoneNumber.replace(/\s/g, '')
      : '';

    if (!phoneNumber) {
      message.error('Please enter mobile number');
      return false;
    }

    if (phoneNumber && !rgxNums.test(phoneNumberWithoutSpaces)) {
      message.error('Please enter a valid mobile number');
      return false;
    }

    if (
      phoneNumber &&
      (isIndia
        ? phoneNumber?.length !== minDigits
        : phoneNumber?.length < minDigits) &&
      !validDigitsRegex.test(phoneNumberWithoutSpaces)
    ) {
      message.error(
        `Please enter a valid  ${
          isIndia ? `${minDigits}-digit Indian` : ''
        } mobile number`,
      );
      return false;
    }

    if (
      phoneNumber &&
      phoneNumber?.length > maxDigits &&
      !maxDigitsRegex.test(phoneNumberWithoutSpaces)
    ) {
      message.error(`The phone number exceeds ${maxDigits} digits`);
      return false;
    }

    // if (!rgx10digit.test(phoneNumber.replace(/\s/g, ''))) {
    //   message.error(
    //     'Please enter a valid 10-digit mobile number without country code',
    //   );
    //   return false;
    // }

    return true;
  };

  const validateEmail = (emailValue: string) => {
    if (!emailValue) {
      message.error('Please enter email.');
      return false;
    }

    if (emailRegex.test(emailValue)) {
      return true;
    }

    message.error('Please enter valid email.');
    return false;
  };

  const getOtp = async (values: any) => {
    let payload = values?.phoneOrMail;

    let [modeValue] = payload.split('#');

    if (mode === 'phone') {
      if (!validatePhoneNumber(modeValue?.replace(/\s/g, ''))) return false;

      payload = modeValue.replace(/\s/g, '');
    } else {
      if (!validateEmail(modeValue?.trim?.().toLowerCase?.())) return false;

      payload = modeValue.trim?.().toLowerCase?.();
    }

    setLoading(true);
    try {
      const response = await (mode === 'phone'
        ? UserAPI.getChangeNumberOTPV2(payload)
        : UserAPI.getChangeEmailOTP(payload));
      if (response.data.code === 0) {
        let redirectTo = '';

        // const afterLoginRedirectTo = localStorage.getItem(
        //   'afterLoginRedirectTo',
        // );

        // if (afterLoginRedirectTo) {
        //   redirectTo = afterLoginRedirectTo;
        //   localStorage.removeItem('afterLoginRedirectTo');
        // }

        message.success('OTP sent successfully.');
        navigate(
          ROUTES.VERIFY_OTP,
          {},
          {
            state: {
              ...(location.state || {}),
              email: mode === 'email' ? payload : location.state?.email,
              [mode]: payload,
              mode,
              blockOtp: false,
              supportNumber: null,
              // countryCode: mode === 'phone' ? 'IN' : undefined,
              countryCode,
              flow: mode === 'phone' ? 'number' : 'email',
              ...(redirectTo ? { redirectTo } : {}),
            },
          },
        );
      } else {
        showAppError(response.data);
      }
    } catch (error: any) {
      showAppError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.countryCode) {
      setCountryCode(location.state.countryCode);
    } else {
      fetchCountryThroughIP();
    }

    if (location.state?.mode) {
      setMode(location.state.mode || 'phone');
    } else {
      handleLogout();
      navigate(ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numberExtension = useMemo(
    () =>
      mode === 'phone' ? (
        <ReactFlagsSelect
          selected={countryCode}
          onSelect={setCountryCode}
          searchable
          showSelectedLabel={false}
          searchPlaceholder="Search countries"
          id="flags-select"
          className="countryFlag"
          showSecondarySelectedLabel
          showOptionLabel
          showSecondaryOptionLabel
          customLabels={COUNTRY_FLAGS_MAP}
        />
      ) : null,
    [countryCode, mode],
  );

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return isLoadingCountry ? (
    <Loader />
  ) : (
    <div className="loginBox verify-box">
      <LoginHeader
        title={`Verify ${mode === 'phone' ? 'phone number' : 'email'}`}
        subtitle="You need to update your phone number in order to access the platform"
        logo={isTagMango ? tmlogo : logo}
      />

      <Form
        form={form}
        layout="vertical"
        className="loginForm"
        onFinish={getOtp}>
        <Form.Item name="phoneOrMail">
          {mode === 'phone' ? (
            <Input
              addonBefore={numberExtension}
              placeholder="Enter your phone number"
              className="loginInputAddon verify-box__login-input--addon"
              type="tel"
            />
          ) : (
            <Input
              addonBefore={numberExtension}
              placeholder="Enter your email"
              className="loginInput"
              type="email"
            />
          )}
        </Form.Item>

        {mode === 'phone' && countryCode !== 'IN' ? (
          <Alert
            message="For International phone number verification, OTP will be sent to your email."
            type="warning"
            style={{ borderRadius: 6, marginBottom: 16 }}
          />
        ) : null}

        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          className="loginFormSubmitBtn">
          Request OTP
        </Button>
      </Form>

      <div className="loginBoxFooter">
        <div className="loginCopyright">
          <CopyrightContent showWhiteLabelAppName />
        </div>

        <Space size="large">
          <a
            className="loginv1BoxFooterLink"
            href={Assets.links.termsAndConditionsPage}
            target="_blank">
            Terms of Use
          </a>

          <a
            className="loginv1BoxFooterLink"
            href={Assets.links.privacyPolicyPage}
            target="_blank">
            Privacy Policy
          </a>

          <a
            href={customSupportLink || 'https://help.tagmango.com/hc/en-us'}
            target="_blank"
            className="loginv1BoxFooterLink"
            rel="noreferrer">
            Help Centre
          </a>
        </Space>
      </div>
    </div>
  );
};

export default VerifyEmailOrPhone;
