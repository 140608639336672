import './styles.scss';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button, message, Typography } from 'antd';
import moment from 'moment';
import { MdInfo } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import ChatAPI from '../../../../api/ChatAPI';
import config from '../../../../api/config';
import Assets from '../../../../assets';
import MessageHeader from '../../../../components/Header/MessageHeader';
import Loader from '../../../../components/Loader';
import {
  removeChatRoomData,
  setShowScrollDown,
} from '../../../../context/chatReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import {
  addNewMessage,
  fetchMoreMessages,
  getMessagesAroundParentMessage,
  markMessagesAsRead,
} from '../../../../context/thunks/chatThunks';
import showAppError from '../../../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { CHAT_NAME_COLORS } from '../../../../shared/Styles';
import { getReadableDateFormat } from '../../../../shared/utils';
import {
  ExtraData,
  IMessage,
} from '../../../../types/chatModels/ChatMessagesResponse';
import { IMangoRoomDetails } from '../../../../types/messageTypes';
import { ROUTES } from '../../../../types/routes';
import BidirectionalScroll, {
  BidirectionalScrollRef,
} from '../../components/BidirectionalScroll';
import ChatInputDisabled from '../../components/ChatInputDisabled/ChatInputDisabled';
import MangoChatScreenHeader from '../../components/MangoChatScreenHeader/MangoChatScreenHeader';
import MessageContents from '../../components/MessageContents/MessageContents';
import MessageOptions, {
  MessageOptionsRefProps,
} from '../../components/MessageOptions';
import ChatInputBox from '../ChatInputBox/ChatInputBox';
import MangoChatInfo from '../MangoChatInfo/MangoChatInfo';

const MangoChatScreen: React.FC = () => {
  const { colors } = useTheme();
  const { roomId } = useParams();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const { chatRoomData, showScrollDown } = useAppSelector(
    (state) => state.chat,
  );
  const userDetails = useAppSelector((state) => state.user);
  const { token } = useAppSelector((state) => state.app);

  const [loadingRoom, setLoadingRoom] = useState<boolean>(true);
  const [roomDetails, setRoomDetails] = useState<IMangoRoomDetails | null>(
    null,
  );
  const [replyMessage, setReplyMessage] = useState<IMessage>();
  const [highlightedMessage, setHighlightedMessage] = useState<IMessage>();
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const colorMap = useRef<Record<string, string>>({});
  const scrollRef = useRef<BidirectionalScrollRef>(null);
  const loading = useRef<boolean>(false);
  const messageOptionsRef = useRef<MessageOptionsRefProps>(null);

  const getRoomDetails = async (id: string) => {
    try {
      setLoadingRoom(true);
      const resp = await ChatAPI.getRoomDetails<IMangoRoomDetails>(id);
      if (resp.status === 200) {
        setRoomDetails({
          ...resp.data.result,
          picUrl:
            resp.data.result.picUrl || resp.data.result.creator.profilePicUrl,
          title: resp.data.result.title || resp.data.result.creator.name,
        });
        setLoadingRoom(false);
      } else {
        showAppError(resp.data);
        navigate(ROUTES.NOTFOUND);
      }
    } catch (error) {
      showAppError(error);
      navigate(ROUTES.NOTFOUND);
    }
  };

  useEffect(() => {
    if (roomId) getRoomDetails(roomId);
    else {
      message.error('Room Id not found');
      navigate(ROUTES.NOTFOUND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    if (roomDetails && roomId && !loading.current) {
      loading.current = true;
      dispatch(
        fetchMoreMessages(roomId, 'up', () => {
          loading.current = false;
          scrollRef.current?.scrollToBottom();
        }),
      );
      dispatch(markMessagesAsRead(roomId, 'mango'));
    }
    return () => {
      dispatch(removeChatRoomData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomDetails]);

  const getNameColor = useCallback(
    (senderId: string) => {
      if (!colorMap.current[senderId]) {
        const color =
          CHAT_NAME_COLORS[Math.floor(Math.random() * CHAT_NAME_COLORS.length)];
        colorMap.current = {
          ...colorMap.current,
          [senderId]: color,
        };
        return color;
      }
      return colorMap.current[senderId];
    },
    [colorMap],
  );

  const getMessagesAround = useCallback(
    (messageId: string) => {
      if (roomId) dispatch(getMessagesAroundParentMessage(messageId, roomId));
    },
    [dispatch, roomId],
  );

  const onClickReply = useCallback(
    (parentMessageId: string) => {
      const index = chatRoomData.messages.findIndex(
        (msg) => msg._id === parentMessageId,
      );
      if (index !== -1) {
        // scroll to item
        scrollRef.current?.scrollToItem(parentMessageId);
      } else {
        // item needs to be loaded from api
        getMessagesAround(parentMessageId);
      }
    },
    [chatRoomData.messages, getMessagesAround],
  );

  const listData = useMemo(() => {
    const listItems: {
      type: 'message' | 'date' | 'new-participant';
      message?: IMessage;
      date?: Date;
      participantDetails?: { participantName: string; participantId: string };
    }[] = [];
    chatRoomData.messages.forEach((item, index) => {
      listItems.unshift({ type: 'message', message: item });
      if (index === chatRoomData.messages.length - 1) {
        listItems.unshift({ type: 'date', date: new Date(item.createdAt) });
      } else if (
        !moment(item.createdAt).isSame(
          chatRoomData.messages[index + 1].createdAt,
          'day',
        )
      ) {
        listItems.unshift({ type: 'date', date: new Date(item.createdAt) });
      }
    });
    // listItemLength.current = listItems.length;
    return listItems;
  }, [chatRoomData.messages]);

  const onStartReached = () => {
    if (chatRoomData.hasMore.previous && !loading.current && roomId) {
      loading.current = true;
      dispatch(
        fetchMoreMessages(roomId, 'up', () => {
          loading.current = false;
        }),
      );
    }
  };

  const onEndReached = () => {
    if (chatRoomData.hasMore.next && !loading.current && roomId) {
      loading.current = true;
      dispatch(
        fetchMoreMessages(roomId, 'down', () => {
          loading.current = false;
        }),
      );
    }
  };

  const downloadMedia = async ({
    contentUrl,
    fileName,
    extraData,
  }: {
    contentUrl: string | undefined;
    fileName: string | undefined;
    extraData?: ExtraData;
  }) => {
    try {
      const link = document.createElement('a');
      link.href = `${
        config.API_ENDPOINT
      }/download-for-chat?url=${contentUrl}&name=${`${fileName}.${extraData?.ext}`}&Authorization=${token}`;
      link.setAttribute('download', fileName || ''); // or any other extension
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        link.parentNode?.removeChild(link);
      }, 200);
    } catch (err) {
      console.log(err);
    }
  };

  const selectMessage = (item: IMessage) => {
    setHighlightedMessage(item);
    setTimeout(() => {
      messageOptionsRef.current?.showMenu();
    }, 100);
  };

  const scrollToBottom = () => {
    if (roomId) {
      if (chatRoomData.hasMore.next) {
        dispatch(removeChatRoomData());
        dispatch(
          fetchMoreMessages(roomId, 'up', () => {
            loading.current = false;
            scrollRef.current?.scrollToBottom();
          }),
        );
      } else {
        scrollRef.current?.scrollToBottom();
      }
    }
  };

  return (
    <>
      <div className="pageRoot">
        {loadingRoom && <Loader size="large" style={{ marginBlock: 24 }} />}

        {roomDetails && !loadingRoom && roomId ? (
          <>
            <MessageHeader
              url={roomDetails.picUrl}
              title={roomDetails.title}
              onClick={() => setShowInfoModal(true)}
            />
            {roomDetails.creator?._id === userDetails.id &&
            !roomDetails.enableSubscriberMessaging ? (
              <div className="subscribersNotAllowed">
                <MdInfo size={20} color={colors.LINK} />
                <Typography.Text className="subscribersNotAllowed__text">
                  Customers are not allowed to message in this room.
                </Typography.Text>
                <Button
                  type="link"
                  className="siteBtn siteBtnLink"
                  onClick={() => {
                    setShowInfoModal(true);
                  }}
                  style={{
                    color: colors.LINK,
                  }}>
                  Change
                </Button>
              </div>
            ) : null}
            <BidirectionalScroll
              ref={scrollRef}
              className="pageContent messageList"
              onShowScrollButton={(show) => {
                dispatch(setShowScrollDown(show));
              }}
              onStartReached={onStartReached}
              onEndReached={onEndReached}>
              <div className="messageList__container">
                <div
                  className="messageList__container__bg-img"
                  style={{
                    backgroundImage: `url(${Assets.images.WhatsAppLightBg})`,
                  }}
                />

                {!chatRoomData.hasMore.previous && roomDetails ? (
                  <MangoChatScreenHeader
                    {...roomDetails}
                    setRoomDetails={setRoomDetails}
                  />
                ) : null}

                <div role="none" className="messageList__spacer" />
                {listData.map(({ date, message: item }) => (
                  <>
                    {item && (
                      <>
                        {item.senderId === userDetails.id ? (
                          <MessageContents
                            key={item._id}
                            message={item}
                            isSenderSelf
                            hideSeen={false}
                            sending={item.sending}
                            time={item.createdAt}
                            text={item.message}
                            type={item.messageType}
                            read={false}
                            replyNameColor={
                              item.parentMessageSender
                                ? getNameColor(item.parentMessageSender._id)
                                : undefined
                            }
                            onClickReply={onClickReply}
                            id={item._id}
                            contentUrl={item.contentUrl}
                            compressedImageUrl={item.compressedImageUrl}
                            extraData={item.extraData}
                            fileName={item.fileName}
                            onOptionsClick={() => selectMessage(item)}
                            downloadMedia={() => {
                              downloadMedia({
                                contentUrl: item.contentUrl,
                                fileName: item.fileName,
                                extraData: item.extraData,
                              });
                            }}
                            isDeleted={item.isDeleted}
                            deletedByCreator={item.deletedByCreator}
                            roomType="mango"
                          />
                        ) : (
                          <MessageContents
                            key={item._id}
                            message={item}
                            isSenderSelf={false}
                            sender={{
                              isCreator:
                                item.senderId === roomDetails.creator?._id,
                              name: item.senderName,
                              profilePicUrl: item.senderProfilePic,
                            }}
                            time={item.createdAt}
                            type={item.messageType}
                            text={item.message}
                            nameColor={getNameColor(item.senderId)}
                            replyNameColor={
                              item.parentMessageSender
                                ? getNameColor(item.parentMessageSender._id)
                                : undefined
                            }
                            compressedImageUrl={item.compressedImageUrl}
                            contentUrl={item.contentUrl}
                            extraData={item.extraData}
                            fileName={item.fileName}
                            id={item._id}
                            onClickReply={onClickReply}
                            onOptionsClick={() => selectMessage(item)}
                            downloadMedia={() => {
                              downloadMedia({
                                contentUrl: item.contentUrl,
                                fileName: item.fileName,
                                extraData: item.extraData,
                              });
                            }}
                            isDeleted={item.isDeleted}
                            deletedByCreator={item.deletedByCreator}
                            roomType="mango"
                          />
                        )}
                      </>
                    )}
                    {date && (
                      <div
                        className="messageList__dateContainer"
                        key={date.getTime()}>
                        <Typography.Text className="messageList__date">
                          {getReadableDateFormat(date).toUpperCase()}
                        </Typography.Text>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </BidirectionalScroll>
            {roomDetails ? (
              roomDetails.creator?._id === userDetails.id ||
              roomDetails.enableSubscriberMessaging ? (
                <ChatInputBox
                  onCloseReply={() => setReplyMessage(undefined)}
                  replyTo={replyMessage}
                  roomId={roomId}
                  onNewRoomCreated={() => {}}
                  onMessageSent={(m, type) => {
                    dispatch(addNewMessage(m, type));
                    setTimeout(() => {
                      scrollRef.current?.scrollToBottom();
                    }, 500);
                  }}
                  replyNameColor={
                    replyMessage
                      ? getNameColor(replyMessage.senderId)
                      : undefined
                  }
                  showScrollDown={showScrollDown}
                  scrollToBottom={scrollToBottom}
                />
              ) : (
                <ChatInputDisabled title="Only creator can send messages." />
              )
            ) : null}
          </>
        ) : null}
      </div>
      {roomDetails && (
        <MangoChatInfo
          roomDetails={roomDetails}
          setRoomDetails={setRoomDetails}
          showModal={showInfoModal}
          closeModal={() => setShowInfoModal(false)}
        />
      )}
      {roomDetails && highlightedMessage && (
        <MessageOptions
          ref={messageOptionsRef}
          message={highlightedMessage}
          onAddReply={() => {
            setReplyMessage(highlightedMessage);
          }}
          downloadMedia={() => {
            if (highlightedMessage)
              downloadMedia({
                contentUrl: highlightedMessage.contentUrl,
                fileName: highlightedMessage.fileName,
                extraData: highlightedMessage.extraData,
              });
          }}
          roomDetails={roomDetails}
          isSenderSelf={highlightedMessage?.senderId === userDetails.id}
          isCreator={roomDetails.creator?._id === userDetails.id}
        />
      )}
    </>
  );
};

export default MangoChatScreen;
