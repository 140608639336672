import './styles.scss';

import React, { useEffect, useState } from 'react';

import { Avatar, message, Modal, Typography } from 'antd';
import { MdAdd } from 'react-icons/md';

import CourseAPI from '../../../api/CourseAPI';
import CloseModal from '../../../assets/svg/CloseModal';
import CustomImage from '../../../components/CustomImage/CustomImage';
import HeaderTextAction from '../../../components/Header/actions/HeaderTextAction';
import Header from '../../../components/Header/Header';
import Input from '../../../components/Input/Input';
import TextArea from '../../../components/Input/TextArea';
import { useTheme } from '../../../context/ThemeProvider';
import showAppError from '../../../shared/error';
import { useAppSelector } from '../../../shared/hooks';
import {
  Creator,
  ICreateCourseCommentRequest,
} from '../../../types/courseTypes';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  chapterId: string;
  creator: Creator;
  onSubmitQuestion?: () => void;
}
const MAX_FILES_ALLOWED = 5;
const AskAQuestion: React.FC<Props> = ({
  showModal,
  closeModal,
  chapterId,
  creator,
  onSubmitQuestion,
}) => {
  const { colors } = useTheme();
  const userDetails = useAppSelector((state) => state.user);
  const [isSaving, setIsSaving] = useState(false);
  const [question, setQuestion] = useState('');
  const [description, setDescription] = useState('');
  const isSubmitButtonEnabled = true;
  const isEdited = React.useRef(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      let files: File[] = [];
      for (let i = 0; i < fileList.length; i++) {
        files.push(fileList[i]);
      }
      if (selectedFiles.length >= MAX_FILES_ALLOWED) {
        message.error(`Maximum ${MAX_FILES_ALLOWED} images are allowed`);
        return;
      }
      if (files.length > MAX_FILES_ALLOWED - selectedFiles.length) {
        message.error(`Maximum ${MAX_FILES_ALLOWED} images are allowed.`);
        files = [...files.slice(0, MAX_FILES_ALLOWED - selectedFiles.length)];
      }

      setSelectedFiles((prev) => [...prev, ...files]);
    }
  };

  const onRemove = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const onSaveButtonPressed = async () => {
    if (!question || question.trim().length === 0) {
      message.error('Question field is required!!');
      return;
    }

    if (selectedFiles.length > 5) {
      message.error('You can upload maximum 5 files!!');
      return;
    }

    setIsSaving(true);

    const reqBody: ICreateCourseCommentRequest = {
      title: question,
      description,
      type: 'qna',
    };

    try {
      const resp = await CourseAPI.createComment(chapterId, reqBody);
      if (resp.status === 200) {
        isEdited.current = true;
        if (onSubmitQuestion) onSubmitQuestion();
        message.success('Question created successfully');
        closeModal();
      } else {
        showAppError(resp.data);
      }
    } catch (error) {
      showAppError(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      setSelectedFiles([]);
      setQuestion('');
      setDescription('');
    }
  }, [showModal]);

  return (
    <Modal
      open={showModal}
      title={
        <Header
          title="Ask a Question"
          backType="cross"
          handleBack={closeModal}
          actionItems={[
            <HeaderTextAction
              text="Submit"
              loadingText="Posting"
              loading={isSaving}
              onClick={onSaveButtonPressed}
              disabled={!isSubmitButtonEnabled}
            />,
          ]}
        />
      }
      centered
      destroyOnClose
      className="askAQuestionModal"
      closable={false}
      footer={null}
      closeIcon={null}>
      <div className="profileCard">
        <Avatar size={40} src={userDetails.profilePic} />
        <div className="profileCard__details">
          <Typography.Title level={5} className="profileCard__name">
            {userDetails.name}
          </Typography.Title>
          <Typography.Text className="profileCard__role">
            {userDetails.id === creator._id ? 'Creator' : 'Subscriber'}
          </Typography.Text>
        </div>
      </div>
      <div>
        <Input
          label="Question"
          text={question}
          onChangeText={(text) => {
            setQuestion(text);
          }}
        />
        <TextArea
          label="Description"
          text={description}
          onChangeText={(text) => {
            setDescription(text);
          }}
        />
        <div
          className="floatingLabelInputWrapper"
          style={{ marginTop: '16px' }}>
          <div className="floatingLabelWrapper">
            <Typography.Text className="floatingLabel">
              Add Photos
            </Typography.Text>
          </div>
          <div className="uploadWrapper">
            {selectedFiles.length > 0 &&
              selectedFiles.map((file, index) => (
                <div key={index} className="uploadWrapper__imageWrapper">
                  <CustomImage src={URL.createObjectURL(file)} />
                  <div
                    className="uploadWrapper__imageWrapper__removeIcon"
                    onClick={() => onRemove(index)}>
                    <CloseModal />
                  </div>
                </div>
              ))}
            <label htmlFor="qnaFileUpload" className="uploadWrapper__addIcon">
              <MdAdd color={colors.TEXT4} />
            </label>
            <input
              id="qnaFileUpload"
              type="file"
              accept="image/*"
              multiple
              max={MAX_FILES_ALLOWED - selectedFiles.length}
              onChange={handleFileChange}
              disabled={isSaving}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default React.memo(AskAQuestion);
