import '../styles.scss';
import 'bitmovin-player/bitmovinplayer-ui.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  HttpRequestType,
  Player,
  PlayerAPI,
  PlayerEvent,
  SourceConfig,
  Technology,
} from 'bitmovin-player';
import { UIManager } from 'bitmovin-player-ui';

import API from '../../../api';
import CourseAPI from '../../../api/CourseAPI';
import { updateMedia } from '../../../context/mediaReducer';
import showAppError from '../../../shared/error';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { detectBrowser, getModifiedURL } from '../../../shared/utils';
import { SubtitleTrack } from '../../../types/courseTypes';
import CompressingVideo from '../CompressingVideo';
import PlayButton from '../PlayButton';
import { createUIContainer } from './BitmovinUIConfig';

interface Props {
  isDRMEnabled?: boolean;
  mediaId: string;
  contentId?: string;
  isCompressDone: boolean;
  mediaUrl: string;
  compressedUrl?: string;
  hlsUrl?: string;
  dashUrl?: string;
  onProgress?: (progress: number, duration: number) => void;
  onEnded?: () => void;
  initalProgress?: number;
  mediaThumb?: string;
  hasError?: boolean;
  errorDescription?: string;
  isCompressedUrl?: boolean;
  subtitleTracks?: SubtitleTrack[];
  courseId?: string;
}

type ErrorMessage = { title: string; subtitle: string };
type ErrorMessagesMap = Record<string, ErrorMessage | (() => ErrorMessage)>;

function isFunction(value: unknown): value is () => ErrorMessage {
  return typeof value === 'function';
}

const errorMessagesMap: ErrorMessagesMap = {
  1103: {
    title: '1103 - There was a problem in loading the video.',
    subtitle:
      'Please try refreshing the page and playing the video again after some time or Contact your creator if the issue persists.',
  },
  1200: () => {
    let unsupportedBrowser = false;

    if (
      navigator &&
      navigator.userAgent &&
      detectBrowser(navigator.userAgent).browser === 'Chrome'
    ) {
      const version = detectBrowser(navigator.userAgent).version.split('.')[0];

      if (parseInt(version, 10) < 129) {
        unsupportedBrowser = true;
      }
    }

    if (unsupportedBrowser) {
      return {
        title: '1200 - This video is not supported by your browser',
        subtitle: 'Please try updating it to the latest version.',
      };
    }
    return {
      title: '1200 - This video is corrupted.',
      subtitle: 'Please ask the creator to re-upload.',
    };
  },
  1208: {
    title: '1208 - Video file is missing.',
    subtitle: 'Please ask the creator to re-upload.',
  },
  1204: {
    title: '1204 - This video is corrupted.',
    subtitle: 'Please ask the creator to re-upload.',
  },
  1301: {
    title: '1301 - This video is corrupted.',
    subtitle: 'Please ask the creator to re-upload.',
  },
  1400: {
    title: '1400 - This video is corrupted.',
    subtitle: 'Please ask the creator to re-upload.',
  },
  2003: {
    title: '2003 - There was a problem in loading the video.',
    subtitle:
      'Please try refreshing the page and playing the video again after some time or contact your creator if the issue persists.',
  },
  2006: {
    title: '2006 - Your device is not compatible to play this video.',
    subtitle:
      'Please try playing this on a different device or contact your creator if the issue persists.',
  },
  2008: {
    title: '2008 - Your device is not compatible to play this video.',
    subtitle:
      'Please try playing this on a different device or contact your creator if the issue persists.',
  },
};

const checkSupportedDRMTypes = async () => {
  const config = [
    {
      initDataTypes: ['cenc'],
      audioCapabilities: [
        {
          contentType: 'audio/mp4;codecs="mp4a.40.2"',
        },
      ],
      videoCapabilities: [
        {
          contentType: 'video/mp4;codecs="avc1.42E01E"',
        },
      ],
    },
  ];
  const drm = {
    Widevine: {
      name: 'Widevine',
      mediaKey: 'com.widevine.alpha',
    },
    PlayReady: {
      name: 'PlayReady',
      mediaKey: 'com.microsoft.playready',
    },
    FairPlay: {
      name: 'FairPlay',
      mediaKey: 'com.apple.fps.1_0',
    },
  };
  let supportedDRMType = '';
  let supportSl3000 = false;
  let drmType = '';
  const drmRequests = Object.keys(drm).map(async (key) => {
    // @ts-expect-error
    if (drm[key].name === 'PlayReady') {
      try {
        await navigator.requestMediaKeySystemAccess(
          'com.microsoft.playready.recommendation.3000',
          config,
        );
        supportSl3000 = true;
      } catch (e) {
        console.log(e);
      }
    }

    try {
      // @ts-expect-error
      await navigator.requestMediaKeySystemAccess(drm[key].mediaKey, config);
      // @ts-expect-error
      return drm[key].name;
    } catch (e) {
      console.log(`${key} :: ${e}`);
      return null;
    }
  });

  const drmResults = await Promise.all(drmRequests);
  supportedDRMType = drmResults.find((result: any) => result !== null);
  drmType = supportedDRMType;

  return { supportedDRMType, supportSl3000, drmType };
};

const BitmovinPlayer: React.FC<Props> = ({
  mediaId,
  contentId,
  isCompressDone,
  mediaThumb = '',
  hasError,
  isDRMEnabled = false,
  initalProgress,
  onProgress,
  onEnded,
  errorDescription,
  isCompressedUrl,
  subtitleTracks,
  courseId,
  ...urls
}) => {
  const { mDeeplinkUrl } = useAppSelector((state) => state.app);
  const userDetails = useAppSelector((state) => state.user);
  const { media } = useAppSelector((state) => state.media);

  const dispatch = useAppDispatch();

  const wakeLock = useRef<WakeLockSentinel | null>(null);

  const [isVideoError, setIsVideoError] = useState(false);
  const [videoError, setVideoError] = useState<{
    title: string;
    subtitle: string;
  }>({
    title: '',
    subtitle: '',
  });

  const [supportedTech, setSupportedTech] = useState<Technology[]>([]);

  const { mediaUrl, compressedUrl, hlsUrl, dashUrl } = useMemo(() => {
    const modifiedUrl = (url?: string) => {
      return getModifiedURL(mDeeplinkUrl, url);
    };
    return {
      mediaUrl: modifiedUrl(urls.mediaUrl),
      compressedUrl: modifiedUrl(urls.compressedUrl),
      hlsUrl: modifiedUrl(urls.hlsUrl),
      dashUrl: modifiedUrl(urls.dashUrl),
    };
  }, [mDeeplinkUrl, urls]);

  const playerConfig = {
    key: 'bf489d14-01f2-4327-b57b-02c23912ddf8',
    ui: false,
    style: {
      aspectratio: '4/3',
    },
    analytics: {
      key: 'cec00acb-0245-4e82-bfd9-30fc9eb6a66f',
      title: mediaId,
      videoId: mediaId,
      customUserId: userDetails?.id as string,
    },
    network: {
      preprocessHttpRequest: (type: any, request: any) => {
        // Setting pallycon customData.
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setCustomData(type, request);
        return Promise.resolve(request);
      },
    },
  };

  // const debounceRef = useRef<NodeJS.Timeout>();
  // const allowUpdateProgress = useRef(true);

  const playerDiv = useRef<HTMLDivElement>(null);
  const player = useRef<PlayerAPI | null>(null);

  const [pallyconToken, setPallyconToken] = useState({
    widevine: '',
    fairplay: '',
    playready: '',
  });

  const [played, setPlayed] = useState(false);

  const handlePlay = () => {
    setPlayed(true);
    dispatch(
      updateMedia({
        mediaId,
        mediaType: 'video',
        playerState: 'playing',
      }),
    );
  };

  const saveLogsOnServer = async (rawError: string) => {
    const logs = [];

    logs.push(`Error: ${rawError}`);
    // add user id to extra log
    logs.push(`User ID: ${userDetails?.id}`);
    // add media id to extra log
    logs.push(`Media ID: ${mediaId}`);
    // add media url to extra log
    logs.push(`HLS Media URL: ${hlsUrl}`);
    logs.push(`Dash URL: ${dashUrl}`);
    logs.push(`Normal URL: ${mediaUrl}`);
    logs.push(`isDRMEnabled: ${isDRMEnabled}`);
    // add media thumb to extra log
    logs.push(`Qencode post id: ${contentId}`);

    if (supportedTech.length) {
      // add supported tech to extra log
      logs.push(`Supported Tech: ${JSON.stringify(supportedTech)}`);
    }

    logs.push(`Browser: ${navigator.userAgent}`);

    const supportedDRMType = await checkSupportedDRMTypes();

    logs.push(`Supported DRM Type: ${JSON.stringify(supportedDRMType)}`);

    const body = `Bug Report: ${logs.join('\n \n')}`;

    try {
      await CourseAPI.saveCourseVideoErrorLogs(
        {
          error: body,
        },
        {
          courseId: courseId ? courseId : '',
          chapterId: mediaId,
        },
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (media && media.mediaId !== mediaId && played) {
      setPlayed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media, mediaId]);

  const checkProgress = (position: number, duration: number) => {
    if (onProgress) {
      onProgress(position, duration);
    }
  };

  /**
   * update progress every 30 seconds
   */
  // const debounceUpdateProgress = (position: number, duration: number) => {
  //   if (allowUpdateProgress.current) {
  //     allowUpdateProgress.current = false;
  //     checkProgress(position, duration);
  //     debounceRef.current = setTimeout(() => {
  //       allowUpdateProgress.current = true;
  //     }, 30000);
  //   }
  // };

  // If You Use Token Reset During Playback Suck As CSL or KeyRotation or AirPlay,
  // Continue to create new tokens and Set them.
  function setCustomData(type: any, request: any) {
    if (!pallyconToken) return;
    switch (type) {
      case HttpRequestType.DRM_LICENSE_WIDEVINE:
        request.headers['pallycon-customdata-v2'] = pallyconToken.widevine;
        break;
      case HttpRequestType.DRM_LICENSE_FAIRPLAY:
        request.headers['pallycon-customdata-v2'] = pallyconToken.fairplay;
        break;
      case HttpRequestType.DRM_LICENSE_PLAYREADY:
        request.headers['pallycon-customdata-v2'] = pallyconToken.playready;
        break;
      default:
        break;
    }
  }

  function setupPlayer() {
    if (!playerDiv.current) return;
    const playerInstance = new Player(playerDiv.current, playerConfig);
    // UIFactory.buildDefaultUI(playerInstance);

    let playerSource: SourceConfig = {
      progressive: mediaUrl,
      poster: mediaThumb,
      options: {
        // withCredentials: false, // This is required for CORS requests to include credentials
        // manifestWithCredentials: true, // for credentials in DASH manifest
      },
    };

    if (subtitleTracks && subtitleTracks.length > 0) {
      playerSource.subtitleTracks = subtitleTracks.map((track, index) => ({
        id: `subtitle-${track.srtName}-${index}`,
        url: track.srtUrl,
        label: track.srtName,
        lang: track.languageCode,
        kind: 'subtitle',
        isDefault: index === 0,
      }));
    }

    if (isDRMEnabled) {
      playerSource = {
        ...playerSource,
        hls: hlsUrl,
        dash: dashUrl,
      };
      playerSource.drm = {
        widevine: {
          LA_URL: 'https://license-global.pallycon.com/ri/licenseManager.do',
          mediaKeySystemConfig: {
            persistentState: 'required',
          },
        },
        playready: {
          LA_URL: 'https://license-global.pallycon.com/ri/licenseManager.do',
        },
        fairplay: {
          LA_URL: 'https://license-global.pallycon.com/ri/licenseManager.do',
          // FairPlay certificate. Required for iOS.
          certificateURL:
            'https://license-global.pallycon.com/ri/fpsKeyManager.do?siteId=KL48',
          prepareContentId: function (cId) {
            return cId.substring(cId.indexOf('skd://') + 6);
          },
          prepareCertificate: function (rawResponse) {
            var responseText = String.fromCharCode.apply(
              null,
              new Uint8Array(rawResponse) as any,
            );
            var raw = window.atob(responseText);
            var rawLength = raw.length;
            var certificate = new Uint8Array(new ArrayBuffer(rawLength));

            for (var i = 0; i < rawLength; i++)
              certificate[i] = raw.charCodeAt(i);

            return certificate;
          },
          useUint16InitData: true,
        },
      };
    } else if (isCompressedUrl) {
      playerSource = {
        ...playerSource,
        hls: compressedUrl,
      };
    }

    // console.log('playerSource', playerSource);

    playerInstance.load(playerSource).then(
      () => {
        const playerSupportedTech = playerInstance.getSupportedTech();
        if (playerSupportedTech) {
          setSupportedTech(playerSupportedTech);
        }
        player.current = playerInstance;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const uiManager = new UIManager(
          playerInstance,
          createUIContainer(playerInstance),
        );
        playerInstance.play().then(() => {
          if (initalProgress && initalProgress < 95) {
            playerInstance.seek(
              (initalProgress * playerInstance.getDuration()) / 100,
            );
          }
        });
        console.log('Successfully loaded source');
      },
      (error) => {
        console.log('Error while loading source:', error);
      },
    );

    playerInstance.on(PlayerEvent.Play, () => {
      if ('wakeLock' in navigator) {
        navigator.wakeLock
          .request('screen')
          .then((wl) => {
            wakeLock.current = wl;
            console.log('wakelock acquired', wl);
          })
          .catch((err) => {
            console.log('wakelock error', err);
          });
      }

      dispatch(
        updateMedia({
          mediaId,
          mediaType: 'video',
          playerState: 'playing',
        }),
      );
      checkProgress(
        playerInstance.getCurrentTime(),
        playerInstance.getDuration(),
      );
    });

    playerInstance.on(PlayerEvent.Paused, () => {
      if (wakeLock.current) {
        wakeLock.current
          .release()
          .then(() => {
            console.log('wakelock released paused');
          })
          .catch((err) => {
            console.log('wakelock error', err);
          });
      }
      dispatch(
        updateMedia({
          mediaId,
          mediaType: 'video',
          playerState: 'paused',
        }),
      );
      checkProgress(
        playerInstance.getCurrentTime(),
        playerInstance.getDuration(),
      );
    });

    playerInstance.on(PlayerEvent.Destroy, () => {
      checkProgress(
        playerInstance.getCurrentTime(),
        playerInstance.getDuration(),
      );
    });

    // playerInstance.on(PlayerEvent.TimeChanged, () => {
    //   if (playerInstance?.isLive()) return;
    //   const position = playerInstance.getCurrentTime();
    //   const duration = playerInstance.getDuration();
    //   debounceUpdateProgress(position, duration);
    // });

    playerInstance.on(PlayerEvent.Error, (error: any) => {
      console.log('Error while playing:', error);

      let errorTitle = error?.name,
        errorSubtitle = '';

      if (error.code) {
        if (error.code in errorMessagesMap) {
          const code = error.code as keyof typeof errorMessagesMap;
          const errorMessage = errorMessagesMap[code];
          errorTitle = isFunction(errorMessage)
            ? errorMessage().title
            : errorMessage.title;
          errorSubtitle = isFunction(errorMessage)
            ? errorMessage().subtitle
            : errorMessage.subtitle;
        } else {
          errorTitle = `${error.code} - ${errorTitle}`;
          errorSubtitle = error?.message;
        }
      }

      const err = {
        title: errorTitle,
        subtitle: errorSubtitle,
      };

      if (courseId) {
        saveLogsOnServer(`Error player event: ${JSON.stringify(err)}`);
      }

      setIsVideoError(true);
      setVideoError(err);
    });

    playerInstance.on(PlayerEvent.PlaybackFinished, () => {
      dispatch(
        updateMedia({
          mediaId,
          mediaType: 'video',
          playerState: 'stopped',
        }),
      );
      checkProgress(playerInstance.getDuration(), playerInstance.getDuration());
      if (onEnded) onEnded();
    });
  }

  function destroyPlayer() {
    if (player && player.current) {
      // console.log('destroying player');
      player.current.destroy();
      player.current = null;
    }
  }

  const getToken = async () => {
    if (!contentId) return;

    Promise.all([
      API.fetchPallyconToken(contentId, 'Widevine'),
      API.fetchPallyconToken(contentId, 'FairPlay'),
      API.fetchPallyconToken(contentId, 'PlayReady'),
    ])
      .then((values) => {
        const widevine = values[0]?.data?.result;
        const fairplay = values[1]?.data?.result;
        const playready = values[2]?.data?.result;

        setPallyconToken({
          widevine,
          fairplay,
          playready,
        });
      })
      .catch((err) => {
        console.log(err);
        showAppError(err);
      });
  };

  useEffect(() => {
    if (!mediaId || !played) {
      return;
    }
    if (isDRMEnabled) getToken();
    else setupPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaId, played]);

  useEffect(() => {
    if (pallyconToken) {
      setupPlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pallyconToken]);

  useEffect(() => {
    return () => {
      destroyPlayer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVideoError) {
      destroyPlayer();
    }
  }, [isVideoError]);

  return (
    <div
      className={
        !isCompressDone || !played || hasError
          ? 'player-wrapper-drm'
          : 'player-wrapper-drm-custom'
      }
      style={{
        backgroundImage:
          isCompressDone && !hasError && !isVideoError
            ? `url(${mediaThumb})`
            : 'none',
        ...(!(isCompressDone && !hasError && !isVideoError) && {
          paddingTop: '75%',
        }),
      }}>
      {isVideoError ? (
        <CompressingVideo
          hasError={true}
          errorDescription={
            videoError.title || 'Unable to play because of issues in the video.'
          }
          errorSubtitle={
            videoError?.subtitle || 'Please contact your creator to fix this.'
          }
        />
      ) : (
        <>
          {!isCompressDone || hasError ? (
            <CompressingVideo
              hasError={hasError}
              errorDescription={errorDescription}
            />
          ) : null}
          {!played && isCompressDone && !hasError ? (
            <PlayButton handlePlay={handlePlay} />
          ) : null}
          {isCompressDone && played && mediaId && !hasError ? (
            <div id={`player_${mediaId}`} ref={playerDiv} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default React.memo(BitmovinPlayer);
