import './styles.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { message, Modal, Typography } from 'antd';
// eslint-disable-next-line import/no-unresolved
import EasyVirtualizedScroller from 'easy-react-virtualized';

import ChatAPI from '../../../../api/ChatAPI';
import MuteNotification from '../../../../assets/svg/MuteNotification';
import Header from '../../../../components/Header/Header';
import Loader from '../../../../components/Loader';
import {
  removeChatRoomData,
  updateMangoRoomData,
} from '../../../../context/chatReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import showAppError from '../../../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { ParticipantListItem } from '../../../../types/chatModels/ParticipantResponse';
import { IMangoRoomDetails } from '../../../../types/messageTypes';
import { ROUTES } from '../../../../types/routes';
import MangoChatScreenHeader from '../../components/MangoChatScreenHeader/MangoChatScreenHeader';
import ChatParticipantItem from './ChatParticipantItem';
import ChatSettingsItem from './ChatSettingsItem';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  roomDetails: IMangoRoomDetails;
  setRoomDetails: (roomDetails: IMangoRoomDetails) => void;
}

interface IState {
  participants: ParticipantListItem[];
  hasMore: boolean;
  page: number;
  loading: boolean;
  refreshing: boolean;
}

const defaultState: IState = {
  loading: false,
  page: 1,
  hasMore: true,
  participants: [],
  refreshing: false,
};

const MangoChatInfo: React.FC<Props> = ({
  showModal,
  closeModal,
  roomDetails,
  setRoomDetails,
}) => {
  const { colors: COLORS } = useTheme();

  const { _id: roomId } = roomDetails;

  const { id } = useAppSelector((state) => state.user);
  const {
    hostMetadata: { offeringTitle },
  } = useAppSelector((state) => state.app);
  const { mangoRoomList } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const [state, setState] = useState<IState>(defaultState);

  const toggleSubscriberMessaging = async () => {
    try {
      await ChatAPI.toggleSubscriberMessagingInRoom(
        roomId,
        !roomDetails.enableSubscriberMessaging,
      );
    } catch (err) {
      showAppError(err);
      return;
    }
    const newRoomList = mangoRoomList.roomList.map((room) => {
      if (room._id === roomId) {
        return {
          ...room,
          enableSubscriberMessaging: !roomDetails.enableSubscriberMessaging,
        };
      }
      return room;
    });
    dispatch(
      updateMangoRoomData({
        ...mangoRoomList,
        roomList: newRoomList,
      }),
    );
    setRoomDetails({
      ...roomDetails,
      enableSubscriberMessaging: !roomDetails.enableSubscriberMessaging,
    });
  };

  const togglePeerConversation = async () => {
    try {
      await ChatAPI.togglePeerToPeerConversationInRoom(roomId);
    } catch (err) {
      showAppError(err);
      return;
    }
    const newRoomList = mangoRoomList.roomList.map((room) => {
      if (room._id === roomId) {
        return {
          ...room,
          peerConversation: !roomDetails.peerConversation,
        };
      }
      return room;
    });
    dispatch(
      updateMangoRoomData({
        ...mangoRoomList,
        roomList: newRoomList,
      }),
    );
    setRoomDetails({
      ...roomDetails,
      peerConversation: !roomDetails.peerConversation,
    });
  };

  const toggleMuteNotification = async () => {
    const newValue = !roomDetails.isNotificationMuted;
    try {
      await ChatAPI.toggleMuteNotificationInRoom(roomId, newValue);
    } catch (err) {
      showAppError(err);
      return;
    }
    const newRoomList = mangoRoomList.roomList.map((room) => {
      if (room._id === roomId) {
        return {
          ...room,
          isNotificationMuted: newValue,
        };
      }
      return room;
    });
    dispatch(
      updateMangoRoomData({
        ...mangoRoomList,
        roomList: newRoomList,
      }),
    );
    setRoomDetails({
      ...roomDetails,
      isNotificationMuted: newValue,
    });
  };

  const fetchRoomParticipants = useCallback(
    async (
      pageCount: number,
      participantsList = state.participants,
      hasMore = state.hasMore,
      loading = state.loading,
      currState = state,
    ) => {
      if (hasMore && !loading) {
        setState({ ...currState, loading: true });
        try {
          const response = await ChatAPI.getAllParticipantsByRoom(
            roomId,
            pageCount,
          );
          const res = response.data;
          let newState = {
            ...currState,
            loading: false,
            refreshing: false,
          };
          if (response.status === 200) {
            if (res.result && res.result.length) {
              const result = response.data.result.map((participant) => {
                if (
                  roomDetails.creator &&
                  roomDetails.creator._id === participant._id
                )
                  return { ...participant, isCreator: true };
                return participant;
              });
              const updatedParticipants =
                pageCount > 1
                  ? [...(participantsList || []), ...result]
                  : [...result];
              newState = {
                ...newState,
                participants: updatedParticipants,
                page: pageCount + 1,
                hasMore: result.length >= 10,
              };
            } else {
              newState = {
                ...newState,
                participants: [...(participantsList || [])],
                hasMore: false,
              };
            }
          }
          setState({ ...state, ...newState });
        } catch (error: any) {
          setState({ ...state, loading: false });
        }
      }
    },
    [roomDetails.creator, roomId, state],
  );

  const loadParticipants = useCallback(() => {
    if (showModal) {
      const newState: IState = {
        participants: [],
        hasMore: true,
        page: 1,
        loading: false,
        refreshing: false,
      };

      setState((prev) => ({
        ...prev,
        participants: [],
        hasMore: true,
        page: 1,
      }));

      fetchRoomParticipants(1, [], true, false, newState);
    }
  }, [showModal, fetchRoomParticipants]);

  useEffect(() => {
    if (roomDetails.participantCount > 0) loadParticipants();
    else setState({ ...state, hasMore: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomDetails]);

  // Alphabetically sort the rest of the participants keeping the room creator at the top and without name ones at the bottom
  const sortedParticipants: ParticipantListItem[] = useMemo(() => {
    if (roomDetails.roomType === 'mango') {
      const creator = state.participants.find(
        (participant) => participant.isCreator,
      );

      const participantsWithName: ParticipantListItem[] = [];
      const participantsWithOutName: ParticipantListItem[] = [];

      state.participants.forEach((participant) => {
        if (!participant.isCreator) {
          const trimmedName = participant.name?.trim();

          if (trimmedName) {
            participantsWithName.push({
              ...participant,
              name: trimmedName,
            });
          } else {
            participantsWithOutName.push(participant);
          }
        }
      });

      participantsWithName.sort((a, b) => a.name.localeCompare(b.name));

      return [
        ...(creator ? [creator] : []),
        ...participantsWithName,
        ...participantsWithOutName,
      ];
    }

    // If room type is not 'mango', there are no participants. So, just return an empty array.
    return [];
  }, [roomDetails, state.participants]);

  return (
    <Modal
      open={showModal}
      title={
        <Header
          title={`${offeringTitle.toTitleCase()} Room Info`}
          handleBack={closeModal}
        />
      }
      destroyOnClose
      className="chatRoomInfoModal"
      closable={false}
      closeIcon={null}
      footer={null}>
      <div className="chatRoomInfoModal__card">
        <MangoChatScreenHeader
          {...roomDetails}
          setRoomDetails={setRoomDetails}
        />
      </div>

      <div className="chatRoomInfoModal__card">
        <Typography.Title level={5} className="chatRoomInfoModal__card__title">
          Chat Settings
        </Typography.Title>
        {roomDetails.creator._id === id ? (
          <>
            <ChatSettingsItem
              title="Allow everyone to message"
              value={roomDetails.enableSubscriberMessaging}
              onChange={toggleSubscriberMessaging}
            />
            <ChatSettingsItem
              title="Allow peer to peer messaging"
              value={roomDetails.peerConversation}
              onChange={togglePeerConversation}
            />
          </>
        ) : null}
        <ChatSettingsItem
          icon={<MuteNotification width={32} height={32} />}
          title="Mute notifications"
          value={roomDetails.isNotificationMuted ?? false}
          onChange={toggleMuteNotification}
        />
      </div>

      <div className="chatRoomInfoModal__card">
        <Typography.Title level={5} className="chatRoomInfoModal__card__title">
          {roomDetails.participantCount} Participant
          {roomDetails.participantCount > 1 ? 's' : ''}
        </Typography.Title>
      </div>

      <EasyVirtualizedScroller
        useParentScrollElement
        hasMore={state.hasMore}
        onLoadMore={async () =>
          fetchRoomParticipants(state.page, state.participants)
        }>
        {sortedParticipants.map((participant) => (
          <ChatParticipantItem
            key={participant._id}
            {...participant}
            onClick={() => {
              if (
                participant._id !== id &&
                (participant._id === roomDetails?.creator._id ||
                  roomDetails?.peerConversation ||
                  roomDetails?.creator._id === id) &&
                !participant.disabledChat
              ) {
                dispatch(removeChatRoomData());
                navigate(ROUTES.MESSAGE_DM_PARTICIPANT, {
                  otherParticipantId: participant._id,
                });
              } else if (participant.disabledChat) {
                message.error('This user has disabled messaging!');
              } else if (
                !roomDetails.peerConversation &&
                participant._id !== id
              ) {
                message.error(
                  'Peer to peer messaging is disabled for this room!',
                );
              } else if (participant._id === id) {
                message.error(`Can't send messages to yourself!`);
              }
            }}
          />
        ))}
      </EasyVirtualizedScroller>

      {state.loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: 12,
            backgroundColor: COLORS.BACKGROUND,
          }}>
          <Loader />
        </div>
      )}
    </Modal>
  );
};
export default MangoChatInfo;
