import {
  Action,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import activityReducer from './activityReducer';
import appReducer from './appReducer';
import chatReducer from './chatReducer';
import mangoReducer from './mangoReducer';
import mediaReducer from './mediaReducer';
import userReducer from './userReducer';

export const store = configureStore({
  reducer: {
    app: appReducer,
    activity: activityReducer,
    user: userReducer,
    media: mediaReducer,
    chat: chatReducer,
    mango: mangoReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
