import WhatsAppLightBg from './img/WhatsApp-light-bg.png';

const Assets = {
  images: {
    WhatsAppLightBg,
    defaultProfilePic:
      'https://tagmango.com/staticassets/avatar-placeholder.png-1612857612139.png',
  },
  links: {
    termsAndConditionsPage: 'https://tagmango.com/terms',
    privacyPolicyPage: 'https://tagmango.com/privacy-policy',
  },
};

export default Assets;
