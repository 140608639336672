import './PlatformCrashed.scss';

import React, { memo, useEffect } from 'react';

import { Button, Space } from 'antd';
import { FaFaceFrown } from 'react-icons/fa6';
import TMIcon from 'tm-icons-library';

import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { ROUTES } from '../../types/routes';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';

const PlatformCrashed: React.FC = () => {
  const navigate = useAppNavigate();

  const { hostMetadata } = useAppSelector((state) => state.app);
  const { id: userId } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (hostMetadata._id && userId) {
      navigate(ROUTES.HOME);
    } else {
      navigate(ROUTES.LOGIN);
    }
  }, [navigate, hostMetadata, userId]);

  return (
    <div className="platform-crashed__container">
      <NoDataFound
        iconElement={<FaFaceFrown size={64} color="#616161" />}
        title="Oops! Something went wrong on our end."
        subtitle="The platform may have crashed. Please try reloading the page."
        subtitle2="If the issue persists again and again, please contact support."
        extra={
          <Space align="center" size={12} style={{ marginTop: 8 }}>
            <Button
              className="siteBtn darkBtn"
              onClick={() => window.location.reload()}>
              <TMIcon name="reload" size={16} color="currentColor" />
              Reload Page
            </Button>
          </Space>
        }
        style={{
          width: '100%',
        }}
      />
    </div>
  );
};

export default memo(PlatformCrashed);
