import './AccessDenied.scss';

import React, { memo } from 'react';

import { FaFaceFrown } from 'react-icons/fa6';

import HomeHeader from '../../components/Header/HomeHeader';
import Loader from '../../components/Loader';
import { useTheme } from '../../context/ThemeProvider';
import { useAppSelector } from '../../shared/hooks';
import NoDataFound from '../Feed/components/NoDataFound/NoDataFound';

const AccessDenied: React.FC = () => {
  const { colors: COLORS } = useTheme();

  const { isLoggedIn } = useAppSelector((state) => state.app);

  return (
    <div className="access-denied__container">
      <HomeHeader title="No Access" />

      <div className="access-denied__content">
        {isLoggedIn ? (
          <NoDataFound
            iconElement={<FaFaceFrown size={64} color={COLORS.ICON} />}
            title="Access Denied!"
            subtitle="Sorry, you don’t have permission to view any page. Please contact your creator."
            subtitle2="You can log out and try again."
            // extra={
            //   <Space align="center" size={12} style={{ marginTop: 8 }}>
            //     <Button
            //       className="siteBtn darkBtn"
            //       onClick={() => window.location.reload()}>
            //       <TMIcon name="reload" size={16} color="currentColor" />
            //       Reload to try again
            //     </Button>
            //   </Space>
            // }
            style={{
              width: '100%',
              padding: 16,
            }}
          />
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default memo(AccessDenied);
