import './styles.scss';

import React, { useState } from 'react';

import { Avatar, Typography } from 'antd';
import { BottomSheet } from 'react-spring-bottom-sheet';
import TMIcon from 'tm-icons-library';

import Assets from '../../../assets';
import TickSvg from '../../../assets/svg/TickSvg';
import BottomSheetTile from '../../../components/BottomSheetTile/BottomSheetTile';
import CustomReadMoreText from '../../../components/CustomReadMoreText/CustomReadMoreText';
import ImageAttachment from '../../../components/ImageAttachment/ImageAttachment';
import MenuButton from '../../../components/MenuButton/MenuButton';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal/ConfirmationModal';
import { useTheme } from '../../../context/ThemeProvider';
import { DELETED_USER } from '../../../shared/data';
import { timeAgo } from '../../../shared/utils';

interface Props {
  type?: 'comment' | 'question';
  qnaId: string;
  attachments?: string[];
  question: string;
  description?: string;
  createdAt: Date;
  imgUrl?: string;
  replyCount?: number;
  userName: string;
  containerStyle?: React.CSSProperties;
  showReply?: boolean;
  onClick?: () => void;
  isAnswered?: boolean;
  showMenu?: boolean;
  onRemoveItem?: () => void;
}

const QNAItem: React.FC<Props> = ({
  type = 'question',
  qnaId,
  question,
  description,
  createdAt,
  imgUrl,
  userName,
  replyCount,
  attachments,
  containerStyle = {},
  showReply = false,
  onClick = () => {},
  isAnswered = false,
  showMenu = false,
  onRemoveItem = () => {},
}) => {
  const { colors } = useTheme();
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  // const linkifiedDescription = useMemo(() => {
  //   return description ? linkifyContent(description) : null;
  // }, [description]);

  return (
    <>
      <div
        className="qna__item"
        id={`qna__item-${qnaId}`}
        style={containerStyle}>
        <Avatar
          size={36}
          src={imgUrl || Assets.images.defaultProfilePic}
          style={{ flexShrink: 0 }}
        />

        <div style={{ marginLeft: 12, width: '100%' }}>
          <div className="qna__item__header">
            <Typography.Title className="qna__item__header__title" level={4}>
              {userName || DELETED_USER}{' '}
              <Typography.Text className="qna__item__header__title__time">
                • {timeAgo(createdAt)}
              </Typography.Text>
            </Typography.Title>

            {showMenu && showReply ? (
              <div className="qna__item__header__menu__wrapper">
                <MenuButton
                  className="qna__item__header__menu"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowBottomSheet(true);
                  }}
                />
              </div>
            ) : null}
          </div>

          {question ? (
            <Typography.Title className="qna__item__question" level={3}>
              {question}
            </Typography.Title>
          ) : null}

          {description ? (
            <div
              style={{
                marginTop: 4,
              }}>
              <CustomReadMoreText
                text={description}
                truncatedLength={200}
                className="qna__item__description"
              />
            </div>
          ) : null}

          {attachments && attachments?.length > 0 ? (
            <ImageAttachment
              containerStyle={{
                marginTop: 12,
              }}
              images={attachments}
              maxImagesAllowed={2}
            />
          ) : null}

          {showReply && replyCount ? (
            <Typography.Title
              onClick={onClick}
              className="qna__item__reply"
              level={5}>
              {replyCount} Repl{replyCount > 1 ? 'ies' : 'y'}
              {isAnswered ? (
                <>
                  <Typography.Text className="qna__item__reply__separator">
                    |
                  </Typography.Text>
                  <Typography.Text className="qna__item__reply__answered">
                    <TickSvg
                      circleColor={colors.GREEN}
                      backgroundColor={colors.BACKGROUND}
                      height={12}
                      width={12}
                    />{' '}
                    {type === 'comment' ? 'Replied' : 'Answered'}
                  </Typography.Text>
                </>
              ) : null}
            </Typography.Title>
          ) : null}
        </div>
      </div>

      <BottomSheet
        open={showBottomSheet}
        onDismiss={() => setShowBottomSheet(false)}>
        <BottomSheetTile
          key={1}
          title="Reply"
          icon={<TMIcon name="arrow-undo" color={colors.ICON} size={24} />}
          onClick={() => {
            setShowBottomSheet(false);
            onClick();
          }}
        />

        <BottomSheetTile
          key={2}
          icon={<TMIcon name="trash" size={20} color="currentColor" />}
          warning
          alignIcon="flex-start"
          title={`Delete ${type}`}
          description={
            type === 'comment'
              ? 'This comment will be removed from this thread'
              : 'The question and all of its replies will be removed from this thread'
          }
          onClick={() => {
            setShowBottomSheet(false);
            setIsDialogVisible(true);
          }}
        />
      </BottomSheet>

      <ConfirmationModal
        open={isDialogVisible}
        message={`Do you really want to delete this ${type}?`}
        title={`Delete ${type.toTitleCase()}`}
        okayButtonText="Delete"
        cancelButtonText="Cancel"
        handleOk={() => {
          setIsDialogVisible(false);
          onRemoveItem();
        }}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
      />
    </>
  );
};

export default React.memo(QNAItem);
