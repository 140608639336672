import './styles.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { message, Modal, Typography } from 'antd';
import { BiCommentCheck, BiCommentX } from 'react-icons/bi';
import { useParams, useSearchParams } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';

import FeedAPI from '../../api/FeedAPI';
import CopyLinkSvg from '../../assets/svg/CopyLink';
import CopyText from '../../assets/svg/CopyText';
import DeleteSvg from '../../assets/svg/Delete';
import EditPostSvg from '../../assets/svg/Edit';
import BottomSheetTile from '../../components/BottomSheetTile/BottomSheetTile';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader';
import MenuButton from '../../components/MenuButton/MenuButton';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import {
  removePost,
  setViewedPostIds,
  updatePost,
} from '../../context/activityReducer';
import { useTheme } from '../../context/ThemeProvider';
import { checkForPermissions } from '../../context/userReducer';
import {
  DELETED_POST_MESSAGE,
  POST_MIN_VIEW_TIME,
} from '../../shared/constants';
import showAppError from '../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import {
  copyToClipboard,
  handleShareLink,
  navigateToOnboardingScreen,
} from '../../shared/utils';
import { ICommentSelected, IPostComment } from '../../types/commentTypes';
import { ROUTES } from '../../types/routes';
import { UAMPermissions } from '../../types/userTypes';
import EditPost from '../CreatePost/EditPost';
import PostComment from '../Feed/Posts/PostComment/PostComment';
import PostCommentInput, {
  PostCommentInputRefProps,
} from '../Feed/Posts/PostComment/PostCommentInput';
import ViewMoreComment from '../Feed/Posts/PostComment/ViewMoreComment';
import PostBlocked from '../Feed/Posts/PostItem/PostBlocked';
import PostContent from '../Feed/Posts/PostItem/PostContent';
import PostHeader from '../Feed/Posts/PostItem/PostHeader';
import PostItem from '../Feed/Posts/PostItem/PostItem';
import PostMedia from '../Feed/Posts/PostItem/PostMedia';
import PostPoll from '../Feed/Posts/PostItem/PostPoll';
import PostStats from '../Feed/Posts/PostItem/PostStats';
import PostTags from '../Feed/Posts/PostItem/PostTags';
import PostSkeleton from '../Feed/Posts/PostSkeleton';

interface Props {
  postId?: string;
  handleBack?: () => void;
}

// type CommentListItem =
//   | { type: 'comment'; data: IPostComment }
//   | { type: 'paginator'; direction: 'old' | 'new'; lastCommentRef: string };

const PostDetailsScreen: React.FC<Props> = ({
  postId: modalPostId,
  handleBack: modalHandleBack,
}) => {
  const { colors } = useTheme();
  const {
    isLoggedIn,
    hostMetadata,
    deeplinkUrl,
    isTagMango,
    isCommunityEnabled,
  } = useAppSelector((state) => state.app);
  const { postState, viewedPostIds } = useAppSelector(
    (state) => state.activity,
  );
  const userDetails = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const { offeringTitle } = hostMetadata;

  const { postId: paramPostId } = useParams<{ postId: string }>();
  const postId = modalPostId || paramPostId;
  const [searchParams] = useSearchParams();

  const [curPost, setCurPost] = useState<any>(null);
  const [loadingPost, setLoadingPost] = useState(true);
  const [postStatus, setPostStatus] = useState<'regular' | 'denied'>('regular');
  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [showEditPostModal, setShowEditPostModal] = useState(false);

  const isMyPost = (curPost?.creatorId || curPost?.creator) === userDetails.id;

  const [comments, setComments] = useState<IPostComment[]>([]);
  const [loadingCommentsType, setLoadingCommentsType] = useState<
    'new' | 'old' | null
  >(null);
  const [hasMoreComments, setHasMoreComments] = useState({
    old: false,
    new: false,
  });
  const [selected, setSelected] = useState<ICommentSelected>({
    type: null,
    commentId: null,
    replyId: null,
    userId: null,
    userName: null,
  });
  const itemsRef = useRef<any[]>([]);
  const [sendingComment, setSendingComment] = useState(false);

  const commentInputRef = useRef<PostCommentInputRefProps>(null);
  const visibilityTimer = useRef<number | null>(null);

  const highlightedCommentId = useMemo(
    () => searchParams.get('commentId') || undefined,
    [searchParams],
  );

  const hasCreatePostAccess: boolean = useMemo(
    () =>
      Boolean(
        isTagMango
          ? userDetails.type === 'creator_completed' ||
              userDetails.type === 'dual'
          : userDetails.id === hostMetadata.creator._id,
      ) ||
      (userDetails.type === 'creator_restricted' &&
        checkForPermissions(
          [UAMPermissions.FEED_WRITE],
          userDetails.userAccessPermissions,
        )) ||
      Boolean(
        userDetails.type === 'fan_completed' &&
          isCommunityEnabled &&
          !userDetails.isBlockedFromCommunityEngagement,
      ),
    [
      hostMetadata.creator._id,
      isCommunityEnabled,
      isTagMango,
      userDetails.id,
      userDetails.isBlockedFromCommunityEngagement,
      userDetails.type,
      userDetails.userAccessPermissions,
    ],
  );

  const postRefDetails = useMemo(() => {
    return curPost?.postRef;
  }, [curPost]);

  const getTitle = () => {
    if (loadingPost && !curPost?._id) return 'Loading User';
    const name: string =
      curPost?.creatorName?.split(' ')?.[0] || 'Unknown User';
    return name.length > 9 ? `${name.substring(0, 9)}...` : name;
  };

  const copyLinktoClipboard = async () => {
    if (curPost?.shortUrl) {
      handleShareLink(curPost?.shortUrl);
    } else {
      handleShareLink(`post/${curPost?._id}`);
    }
  };

  const handleBack = () => {
    if (modalHandleBack) {
      modalHandleBack();
      return;
    }
    if (navigate.canGoBack()) navigate.goBack();
    else {
      navigateToOnboardingScreen(userDetails.type, navigate);
    }
  };

  const onDelete = () => {
    const errorMsg = 'Failed to delete post';
    if (postId) {
      setIsDialogVisible(false);
      handleBack();
      dispatch(removePost(postId));
      FeedAPI.deletePost(postId);
      message.success('Post deleted successfully');
    } else {
      message.error(errorMsg);
    }
  };

  const getPost = async (id: string) => {
    // const errorMsg = 'Failed to load post';
    try {
      const res = await FeedAPI.getPostById(id);
      if (res.status === 200) {
        setCurPost(res.data.result.posts[0]);
        setPostStatus('regular');
        setLoadingPost(false);
      } else {
        showAppError(res.data);
      }
    } catch (err: any) {
      showAppError(err);
      if (err?.response && err?.response.status === 403) {
        setCurPost(err.response.data.result);
        setPostStatus('denied');
        setLoadingPost(false);
      } else {
        handleBack();
      }
    }
  };

  const increaseAndUpdatePostViews = async () => {
    if (!postId || !curPost) return;

    try {
      const resp = await FeedAPI.increasePostViews(postId);
      if (resp.status === 200) {
        // console.log('Post views increased:', postId);
        setCurPost({
          ...curPost,
          views: (curPost?.views || 0) + 1,
        });
        // add to viewed set to avoid multiple view count increase
        dispatch(setViewedPostIds(postId));
      }
    } catch (error) {
      console.log('Error while increasing post views', error);
    }
  };

  const loadCommentsFromMiddle = async (comment: string) => {
    setLoadingCommentsType('new');
    try {
      const resp = await FeedAPI.getCommentsForDeeplink(comment);
      if (resp.status === 200) {
        setComments(resp.data.result.allComments);
        setHasMoreComments({
          old: true,
          new: true,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingCommentsType(null);
    }
  };

  const getCommentsBeforeOrAfter = async (
    lastCommentId: string,
    direction: 'old' | 'new',
  ) => {
    const errorMsg = 'Failed to load comments';
    setLoadingCommentsType(direction);
    try {
      if (isLoggedIn && postId) {
        const resp = await FeedAPI.getCommentsByPost(
          postId,
          direction,
          lastCommentId || undefined,
        );
        if (resp.status === 200) {
          setHasMoreComments((prev) => ({
            ...prev,
            [direction]: resp.data.result.remainingCount > 0,
          }));
          if (direction === 'old') {
            setComments((prev) => [...prev, ...resp.data.result.result]);
          } else {
            setComments((prev) => [...resp.data.result.result, ...prev]);
          }
        }
      }
    } catch (err: any) {
      if (
        err?.response &&
        (err?.response.status === 403 || err?.response.status === 400)
      ) {
        return;
      }
      message.error(errorMsg);
    } finally {
      setLoadingCommentsType(null);
    }
  };

  useEffect(() => {
    const commentRef = searchParams.get('commentId');

    setComments([]);
    setHasMoreComments({
      old: false,
      new: false,
    });
    // load the comment section
    if (commentRef) {
      // this page was probably opened with a deeplink with comment reference, load comments section from middle
      loadCommentsFromMiddle(commentRef);
    } else {
      // load comment section from start
      // getComments(1);
      getCommentsBeforeOrAfter('', 'old');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, comments.length);
  }, [comments]);

  useEffect(() => {
    if (postId) {
      const val = postState.posts.find((p) => p._id === postId);
      if (val) {
        setCurPost(val);
        setLoadingPost(false);
      } else {
        getPost(postId);
      }
    } else {
      navigateToOnboardingScreen(userDetails.type, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, postState.posts]);

  useEffect(() => {
    if (selected.commentId) {
      commentInputRef.current?.focusInput();
    }
  }, [selected]);

  const onEnableOrDisableComments = async (value: boolean = false) => {
    const errorMsg = `Failed to ${value ? 'disable' : 'enable'} comments`;
    if (curPost && curPost._id) {
      const newPost = {
        ...curPost,
        disableEngagement: value,
      };
      dispatch(updatePost(newPost));
      setCurPost(newPost);
      setIsBottomSheetVisible(false);
      try {
        const resp = await FeedAPI.toggleEnableOrDisableComments(
          curPost._id,
          value,
        );
        if (resp.status === 200) {
          message.success(
            `Comments ${value ? 'disabled' : 'enabled'} successfully!`,
          );
        } else {
          message.error(errorMsg);
        }
      } catch {
        message.error(errorMsg);
      }
    }
  };

  useEffect(() => {
    const post = postState.posts.find((item) => item._id === postId);

    if (
      !post ||
      (postId && viewedPostIds.includes(postId)) ||
      post.creatorId === userDetails.id
    ) {
      // Clean up any existing timer if the post is already viewed
      if (visibilityTimer.current) {
        clearTimeout(visibilityTimer.current);
        visibilityTimer.current = null;
      }
      return;
    }

    // Start a timer to track view duration
    visibilityTimer.current = window.setTimeout(() => {
      // If the timer completes, consider the post as viewed
      increaseAndUpdatePostViews();
      visibilityTimer.current = null;
    }, POST_MIN_VIEW_TIME);

    // Cleanup the timer when the component is unmounted or dependencies change
    return () => {
      if (visibilityTimer.current) {
        clearTimeout(visibilityTimer.current);
        visibilityTimer.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, postState.posts, viewedPostIds, userDetails.id]);

  return (
    <>
      <div className="postdetails__container">
        <Header
          title={`${getTitle()}'s post`}
          handleBack={handleBack}
          actionItems={
            postStatus === 'denied'
              ? []
              : (curPost && [
                  <MenuButton
                    onClick={() => {
                      setIsBottomSheetVisible(true);
                    }}
                  />,
                ]) ||
                []
          }
        />

        <div className="scroll__container">
          <div
            style={{
              borderBottom: `1px solid ${colors.BORDER}`,
            }}>
            {!loadingPost && curPost ? (
              <>
                {curPost.isDeleted ? (
                  <PostContent
                    caption={DELETED_POST_MESSAGE}
                    maxLines={5}
                    onCaptionPressed={() => {}}
                    style={{ fontWeight: 600 }}
                  />
                ) : (
                  <>
                    <PostTags tags={curPost.tags || []} />

                    <PostHeader
                      post={curPost}
                      mode="details"
                      postStatus={postStatus}
                    />

                    {postStatus === 'denied' ? (
                      <div className="creatorPostMediaWrapper">
                        <PostBlocked
                          userLoggedIn={isLoggedIn}
                          showPurchaseButton={curPost.mangoArr?.length > 0}
                          onClick={() => {
                            if (!isLoggedIn) {
                              navigate(ROUTES.LOGIN, undefined, {
                                state: {
                                  redirectTo: ROUTES.POST_DETAILS.replace(
                                    ':postId',
                                    postId || '',
                                  ),
                                },
                              });
                              return;
                            }
                            let url;
                            if (curPost.mangoArr?.length === 1) {
                              url = isTagMango
                                ? `${deeplinkUrl}mangocheckout/${curPost.mangoArr[0]._id}`
                                : `${deeplinkUrl}web/checkout/${curPost.mangoArr[0]._id}`;
                              url += '?purchaseNow=true';
                            } else {
                              url = `${deeplinkUrl}creator/profile/${
                                curPost.creatorId || curPost.creator
                              }`;
                            }
                            window.open(url);
                          }}
                        />
                      </div>
                    ) : null}

                    {curPost.caption && postStatus === 'regular' ? (
                      <PostContent
                        caption={
                          curPost.isDeleted
                            ? DELETED_POST_MESSAGE
                            : curPost.caption
                        }
                        maxLines={curPost.contentUrl ? 2 : 5}
                        onCaptionPressed={() => {}}
                      />
                    ) : null}

                    {postStatus === 'regular' ? (
                      <PostMedia
                        isDrmEnabled={curPost.isDrmEnabled}
                        vdoCipherId={curPost.videoCipherId}
                        url={curPost.contentUrl}
                        type={curPost.contentType}
                        // eslint-disable-next-line no-underscore-dangle
                        id={curPost._id}
                        isCompressDone={curPost?.isCompressDone}
                        index={0}
                        thumbnail={curPost.thumbnail}
                        compressedUrl={curPost.compressedVideoUrl}
                        hlsUrl={curPost.compressedVideoUrlHls}
                        dashUrl={curPost.compressedVideoUrlDash}
                        contentId={curPost.qencodePostId}
                        // drmType={checkBrowser().drmType}
                        typeOfPost="post"
                        hasError={curPost.isCompressError}
                        errorDescription={curPost.compressErrorDescription}
                      />
                    ) : null}

                    {curPost.poll && curPost.contentType === 'poll' ? (
                      <PostPoll
                        pollId={curPost.poll._id}
                        isOwnPost={curPost.creatorId === userDetails.id}
                        isAnswered={curPost.poll.hasVoted}
                        answerOption={curPost.poll.voteDetails?.pollOption}
                        question={curPost.poll.question}
                        options={curPost.poll.pollOptions}
                        individualVotes={curPost.poll.voteCounts}
                        totalVotes={curPost.poll.totalVotes}
                        endDate={curPost.poll.pollEndAt}
                        updatePost={(newPoll: any) => {
                          dispatch(
                            updatePost({
                              ...curPost,
                              poll: newPoll,
                            }),
                          );
                          setCurPost({ ...curPost, poll: newPoll });
                        }}
                      />
                    ) : null}

                    {postRefDetails?._id ? (
                      <div className="repost-card">
                        <PostItem
                          key={postRefDetails?._id}
                          index={0}
                          post={postRefDetails}
                          isRepost={Boolean(postRefDetails?._id)}
                        />
                      </div>
                    ) : null}

                    {postStatus === 'regular' ? (
                      <PostStats
                        post={curPost}
                        hasCreatePostAccess={hasCreatePostAccess}
                        onCommentsPressed={() => {
                          setSelected({
                            type: null,
                            commentId: null,
                            replyId: null,
                            userId: null,
                            userName: null,
                          });
                        }}
                        onRepostPressed={() =>
                          navigate(
                            ROUTES.CREATE_POST,
                            { postRefId: curPost?.postRef?._id || curPost._id }, // If reposted post is getting reposted, then postRefId will be the original post id which is being reposted in the first place
                            { state: { postRefDetails: curPost } },
                          )
                        }
                        hideRepostOption // Hide repost option in post details screen
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <PostSkeleton />
            )}
          </div>

          {postStatus === 'regular' &&
          !loadingPost &&
          !curPost?.disableEngagement ? (
            <div className="postCommentListWrapper">
              <ul className="postCommentList">
                {hasMoreComments.new ? (
                  <ViewMoreComment
                    key={`${comments[0]._id}--new`}
                    title="View previous comments"
                    onClick={() => {
                      getCommentsBeforeOrAfter(comments[0]._id, 'new');
                    }}
                  />
                ) : null}

                {loadingCommentsType === 'new' ? <Loader /> : null}

                {comments.map((item, index) => (
                  <PostComment
                    creator={curPost.creatorId}
                    onRemoveComment={async () => {
                      try {
                        const resp = await FeedAPI.deleteComment(item._id);
                        if (resp.status === 200) {
                          const newComments = [...comments];

                          newComments.splice(index, 1);

                          setComments(newComments);
                          updatePost({
                            ...curPost,
                            commentCount: curPost.commentCount - 1,
                            replyCount: curPost.replyCount - item.replyCount,
                          });
                        }
                      } catch (err) {
                        showAppError(err);
                      }
                    }}
                    ref={(i) => {
                      itemsRef.current[index] = i;
                    }}
                    key={item._id}
                    userId={item.userId || ''}
                    name={item.userName || ''}
                    profilePic={item.imgUrl || ''}
                    content={item.text}
                    liked={item.liked}
                    likeCount={item?.likedBy?.length || 0}
                    replyCount={item.replyCount}
                    onUpdateReplyCount={(newCount: number) => {
                      updatePost({
                        ...curPost,
                        replyCount:
                          curPost.replyCount +
                          (newCount - comments[index].replyCount),
                      });

                      const newComments = [...comments];

                      newComments[index].replyCount = newCount;

                      setComments(newComments);
                    }}
                    createdAt={item.createdAt}
                    commentId={item._id}
                    highlightedCommentId={highlightedCommentId}
                    commentImages={item?.images || []}
                    selected={selected}
                    setSelected={(val: ICommentSelected) => setSelected(val)}
                    toggleLikeComment={() => {
                      const newComments = [...comments];

                      newComments[index].liked = !newComments[index].liked;

                      if (newComments[index].liked) {
                        newComments[index].likedBy.push(userDetails.id);
                      } else {
                        newComments[index].likedBy = newComments[
                          index
                        ].likedBy.filter((x) => x !== userDetails.id);
                      }

                      setComments(newComments);
                    }}
                  />
                ))}

                {loadingCommentsType === 'old' ? <Loader /> : null}

                {hasMoreComments.old ? (
                  <ViewMoreComment
                    key={`${comments[comments.length - 1]._id}--old`}
                    title="View more comments"
                    onClick={() => {
                      getCommentsBeforeOrAfter(
                        comments[comments.length - 1]._id,
                        'old',
                      );
                    }}
                  />
                ) : null}
              </ul>
            </div>
          ) : null}
        </div>

        {!loadingPost &&
        postStatus === 'regular' &&
        !curPost?.disableEngagement &&
        !userDetails.isBlockedFromCommunityEngagement ? (
          <PostCommentInput
            ref={commentInputRef}
            reply={selected}
            onReplyClose={() => {
              setSelected({
                type: null,
                commentId: null,
                replyId: null,
                userId: null,
                userName: null,
              });
            }}
            isSending={sendingComment}
            postId={curPost?._id || ''}
            onComment={async (commentText, file) => {
              // if (newComment.trim() === '') return;

              setSendingComment(true);
              try {
                const res = await FeedAPI.createCommentV2({
                  postId: curPost._id ?? '',
                  text: commentText,
                  file,
                  commentRef: selected.commentId ?? undefined,
                });
                if (res.data.code === 0) {
                  commentInputRef.current?.clearInput();

                  const user =
                    userDetails.type === 'creator_restricted'
                      ? hostMetadata.creator
                      : {
                          _id: userDetails.id,
                          name: userDetails.name,
                          profilePicUrl: userDetails.profilePic,
                        };
                  const newComment = {
                    ...res.data.result,
                    checkedlikes: [],
                    imgUrl: user.profilePicUrl as string,
                    liked: false,
                    replies: [],
                    replyCount: 0,
                    userId: user._id as string,
                    userName: user.name as string,
                  };

                  if (selected.type) {
                    const index = comments.findIndex(
                      (comment) => comment._id === selected.commentId,
                    );

                    itemsRef.current[index].addReply(newComment);

                    setComments((prev) =>
                      prev.map((item, i) => {
                        if (i !== index) return item;

                        return {
                          ...item,
                          replyCount: item.replyCount + 1,
                        };
                      }),
                    );
                    dispatch(
                      updatePost({
                        ...curPost,
                        replyCount: curPost.replyCount + 1,
                      }),
                    );
                  } else {
                    const newComments: IPostComment[] = [
                      newComment as unknown as IPostComment,
                      ...comments,
                    ];
                    setComments(newComments);
                    dispatch(
                      updatePost({
                        ...curPost,
                        commentCount: curPost.commentCount + 1,
                      }),
                    );
                  }
                } else {
                  showAppError(res.data);
                }
              } catch (err) {
                showAppError(err);
              } finally {
                setSendingComment(false);
                setSelected({
                  type: null,
                  commentId: null,
                  replyId: null,
                  userName: null,
                  userId: null,
                });
              }
            }}
          />
        ) : curPost?.disableEngagement ? (
          <Typography.Paragraph
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '20px 0',
            }}>
            Comments are disabled for this post!
          </Typography.Paragraph>
        ) : userDetails.isBlockedFromCommunityEngagement ? (
          <Typography.Paragraph
            style={{
              width: '100%',
              textAlign: 'center',
              margin: '20px 0',
            }}>
            You are blocked from community engagement by the creator
          </Typography.Paragraph>
        ) : null}
      </div>

      <BottomSheet
        open={isBottomSheetVisible}
        onDismiss={() => setIsBottomSheetVisible(false)}>
        {curPost?.caption && curPost?.contentType !== 'poll' ? (
          <BottomSheetTile
            icon={<CopyText width={20} height={20} />}
            title="Copy caption"
            onClick={() => {
              copyToClipboard(curPost.caption);
              setIsBottomSheetVisible(false);
            }}
          />
        ) : null}

        <BottomSheetTile
          icon={<CopyLinkSvg width={20} height={20} />}
          title="Copy link"
          onClick={() => {
            copyLinktoClipboard();
            setIsBottomSheetVisible(false);
          }}
        />

        {postStatus === 'regular' && isMyPost ? (
          <>
            {curPost?.contentType !== 'poll' ? (
              <BottomSheetTile
                icon={<EditPostSvg width={18} height={18} />}
                title="Edit post"
                onClick={() => {
                  setIsBottomSheetVisible(false);
                  setShowEditPostModal(true);
                }}
              />
            ) : null}

            {!isTagMango ? (
              curPost.disableEngagement ? (
                <BottomSheetTile
                  icon={<BiCommentCheck color={colors.ICON} size={20} />}
                  title="Enable comments"
                  description="Commenting will be enabled in this post"
                  onClick={() => {
                    onEnableOrDisableComments(false);
                  }}
                />
              ) : (
                <BottomSheetTile
                  icon={<BiCommentX color={colors.ICON} size={20} />}
                  title="Disable comments"
                  description="Commenting would be disabled in this post"
                  onClick={() => {
                    onEnableOrDisableComments(true);
                  }}
                />
              )
            ) : null}

            <hr className="dropdownDivider" />

            <BottomSheetTile
              icon={<DeleteSvg width={20} height={20} />}
              warning
              alignIcon="flex-start"
              title="Remove post"
              description={`The post will be removed from your ${offeringTitle} feed`}
              onClick={() => {
                setIsBottomSheetVisible(false);
                setIsDialogVisible(true);
              }}
            />
          </>
        ) : null}
      </BottomSheet>

      <ConfirmationModal
        title="Remove"
        open={isDialogVisible}
        handleOk={onDelete}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
        message="Do you really want to delete the post?"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
      />

      <Modal
        open={showEditPostModal}
        title={null}
        footer={null}
        closable={false}
        destroyOnClose
        className="postDetailsModal">
        <EditPost
          post={curPost}
          handleBack={() => {
            setShowEditPostModal(false);
          }}
          onEditCallback={(newPost) => {
            setCurPost(newPost);
          }}
        />
      </Modal>
    </>
  );
};

export default PostDetailsScreen;
