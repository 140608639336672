import React, { useState } from 'react';

import {
  Avatar,
  message,
  Modal,
  Typography,
} from 'antd';
import {
  BiCommentCheck,
  BiCommentX,
} from 'react-icons/bi';
import {
  TbPin,
  TbPinnedOff,
} from 'react-icons/tb';
import { BottomSheet } from 'react-spring-bottom-sheet';

import FeedAPI from '../../../../api/FeedAPI';
import CopyLinkSvg from '../../../../assets/svg/CopyLink';
import CopyTextSvg from '../../../../assets/svg/CopyText';
import DeleteSvg from '../../../../assets/svg/Delete';
import EditPostSvg from '../../../../assets/svg/Edit';
import ViewMoreSvg from '../../../../assets/svg/ViewMore';
import BottomSheetTile
  from '../../../../components/BottomSheetTile/BottomSheetTile';
import MenuButton from '../../../../components/MenuButton/MenuButton';
import ConfirmationModal
  from '../../../../components/Modals/ConfirmationModal/ConfirmationModal';
import {
  onRefresh,
  removePost,
  updatePost,
} from '../../../../context/activityReducer';
import { useTheme } from '../../../../context/ThemeProvider';
import { checkForPermissions } from '../../../../context/userReducer';
import {
  DEFAULT_AVATAR,
  DELETED_USER,
  UNKNOWN_USER,
} from '../../../../shared/data';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
  useTimeAgoHook,
} from '../../../../shared/hooks';
import {
  copyToClipboard,
  handleShareLink,
} from '../../../../shared/utils';
import { ROUTES } from '../../../../types/routes';
import { UAMPermissions } from '../../../../types/userTypes';
import EditPost from '../../../CreatePost/EditPost';
import MangoTitle from '../MangoTitle';

interface Props {
  post: any;
  mode?: 'details' | 'activity' | null;
  postStatus?: 'regular' | 'denied';
  allowRedirectToUserProfile?: boolean;
}

const PostHeader: React.FC<Props> = ({
  post,
  mode = 'activity',
  postStatus = 'regular',
  allowRedirectToUserProfile = true,
}) => {
  const navigate = useAppNavigate();

  const { colors } = useTheme();

  const dispatch = useAppDispatch();

  const userDetails = useAppSelector((state) => state.user);
  const {
    hostMetadata: {
      offeringTitle,
      creator: { _id: hostCreatorId },
    },
    isTagMango,
  } = useAppSelector((state) => state.app);

  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [showEditPostModal, setShowEditPostModal] = useState(false);

  const isMyPost = post?.creatorId === userDetails.id;
  const isCreatorPost = post?.creatorId && hostCreatorId === post?.creatorId;

  const hasFeedWriteAccess =
    userDetails?.type === 'creator_completed' ||
    (userDetails?.type === 'creator_restricted' &&
      checkForPermissions(
        [UAMPermissions.FEED_WRITE],
        userDetails.userAccessPermissions,
      ));

  const goToUserProfile = () => {
    if (!post || postStatus === 'denied' || !(post.creatorId || post.creator))
      return;

    if (post) {
      navigate(ROUTES.USER_PROFILE, {
        userId: post.creatorId || post.creator,
      });
    }
  };

  const copyLinktoClipboard = async () => {
    // if (post.shortUrl) {
    //   handleShareLink(post.shortUrl, true);
    // } else {
    //   handleShareLink(`post/${post._id}`);
    // }

    handleShareLink(
      `web${ROUTES.POST_METADATA_VIEW.replace(':postId', post._id)}`,
    );
  };

  const onDelete = () => {
    setIsDialogVisible(false);
    dispatch(removePost(post._id));
    FeedAPI.deletePost(post._id);
    message.success('Post deleted successfully');
  };

  const onEnableOrDisableComments = async (value: boolean = false) => {
    if (post && post._id) {
      const newPost = {
        ...post,
        disableEngagement: value,
      };
      dispatch(updatePost(newPost));
      setIsBottomSheetVisible(false);
      try {
        const resp = await FeedAPI.toggleEnableOrDisableComments(
          post._id,
          value,
        );
        if (resp.status === 200) {
          message.success(
            `Comments ${value ? 'disabled' : 'enabled'} successfully!`,
          );
        } else {
          message.error("Couldn't update! Something went wrong.");
        }
      } catch {
        message.error("Couldn't update! Something went wrong.");
      }
    }
  };

  const onTogglePinPost = async () => {
    // const newPost = {
    //   ...post,
    //   isPinned: !post.isPinned,
    // };
    // dispatch(updatePost(newPost));
    setIsBottomSheetVisible(false);
    try {
      const resp = await FeedAPI.togglePinPost(post._id);
      if (resp.status === 200) {
        message.success(
          `Post ${post.isPinned ? 'unpinned' : 'pinned'} successfully!`,
        );
        dispatch(onRefresh());
      } else {
        message.error("Couldn't update! Something went wrong.");
      }
    } catch {
      message.error("Couldn't update! Something went wrong.");
    }
  };

  return (
    <>
      <div className="creatorPostHeader">
        <Avatar
          src={
            (isMyPost
              ? userDetails.profilePic
              : post.imgUrl || post.creatorImage) || DEFAULT_AVATAR
          }
          size={45}
          onClick={allowRedirectToUserProfile ? goToUserProfile : undefined}
          style={{ cursor: 'pointer' }}
        />

        <div className="postHeaderDetailsWrapper">
          <Typography.Text
            ellipsis={true}
            className="postCreator"
            style={{ cursor: 'pointer' }}
            onClick={allowRedirectToUserProfile ? goToUserProfile : undefined}>
            {postStatus === 'denied'
              ? UNKNOWN_USER
              : (isMyPost ? userDetails.name : post.creatorName || '') ||
                DELETED_USER}
          </Typography.Text>

          <div className="postHeaderDetails">
            <MangoTitle mangoes={post?.mangoArr || []}>
              {useTimeAgoHook(
                post.updatedAt || post.scheduleTime || post.createdAt,
              )}
              &nbsp;•&nbsp;
            </MangoTitle>
          </div>
        </div>

        {mode === 'activity' ? (
          <MenuButton onClick={() => setIsBottomSheetVisible(true)} />
        ) : null}
      </div>

      <BottomSheet
        open={isBottomSheetVisible}
        onDismiss={() => setIsBottomSheetVisible(false)}>
        <BottomSheetTile
          icon={<ViewMoreSvg width={20} height={20} />}
          title="View more"
          onClick={() => {
            navigate(ROUTES.POST_DETAILS, { postId: post._id });
          }}
        />
        {post.caption && post.contentType !== 'poll' ? (
          <BottomSheetTile
            icon={<CopyTextSvg width={20} height={20} />}
            title="Copy caption"
            onClick={() => {
              copyToClipboard(post.caption);
              setIsBottomSheetVisible(false);
            }}
          />
        ) : null}

        <BottomSheetTile
          icon={<CopyLinkSvg width={20} height={20} />}
          title="Copy link"
          onClick={() => {
            copyLinktoClipboard();
            setIsBottomSheetVisible(false);
          }}
        />

        {isMyPost || (!isTagMango && hasFeedWriteAccess) ? (
          <>
            {post.contentType !== 'poll' ? (
              <BottomSheetTile
                icon={<EditPostSvg width={18} height={18} />}
                title="Edit post"
                onClick={() => {
                  setIsBottomSheetVisible(false);
                  setShowEditPostModal(true);
                }}
              />
            ) : null}

            {!isTagMango && hasFeedWriteAccess ? (
              <BottomSheetTile
                icon={
                  post.isPinned ? (
                    <TbPinnedOff color={colors.ICON} size={20} />
                  ) : (
                    <TbPin color={colors.ICON} size={20} />
                  )
                }
                title={`${post.isPinned ? 'Unpin' : 'Pin'} post`}
                onClick={onTogglePinPost}
              />
            ) : null}

            {(isMyPost || (isCreatorPost && hasFeedWriteAccess)) &&
            !isTagMango ? (
              post.disableEngagement ? (
                <BottomSheetTile
                  icon={<BiCommentCheck color={colors.ICON} size={20} />}
                  title="Enable comments"
                  description="Commenting will be enabled in this post"
                  onClick={() => {
                    onEnableOrDisableComments(false);
                  }}
                />
              ) : (
                <BottomSheetTile
                  icon={<BiCommentX color={colors.ICON} size={20} />}
                  title="Disable comments"
                  description="Commenting would be disabled in this post"
                  onClick={() => {
                    onEnableOrDisableComments(true);
                  }}
                />
              )
            ) : null}

            <hr className="dropdownDivider" />

            <BottomSheetTile
              icon={<DeleteSvg width={20} height={20} />}
              warning
              alignIcon="flex-start"
              title="Remove post"
              description={`The post will be removed from your ${offeringTitle} feed`}
              onClick={() => {
                setIsBottomSheetVisible(false);
                setIsDialogVisible(true);
              }}
            />
          </>
        ) : null}
      </BottomSheet>

      <ConfirmationModal
        title="Remove"
        open={isDialogVisible}
        handleOk={onDelete}
        handleCancel={() => {
          setIsDialogVisible(false);
        }}
        message="Do you really want to delete the post?"
        okayButtonText="Delete"
        cancelButtonText="Cancel"
      />

      <Modal
        open={showEditPostModal}
        title={null}
        footer={null}
        closable={false}
        destroyOnClose
        className="postDetailsModal">
        <EditPost
          post={post}
          handleBack={() => {
            setShowEditPostModal(false);
          }}
          canEditSubscriberPost={
            !isCreatorPost && !isTagMango && hasFeedWriteAccess
          }
        />
      </Modal>
    </>
  );
};

export default React.memo(PostHeader);
