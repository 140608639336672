import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Typography } from 'antd';
import { FaCheckSquare } from 'react-icons/fa';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { useTheme } from '../../context/ThemeProvider';
import NoDataFound from '../../screens/Feed/components/NoDataFound/NoDataFound';
import { useAppSelector } from '../../shared/hooks';
import { IMango } from '../../types/feedTypes';
import HeaderTextAction from '../Header/actions/HeaderTextAction';
import Header from '../Header/Header';
import SearchBar from '../Header/SearchBar';
import MangoSelectorCard from './MangoSelectorCard';

type IFilterObj = {
  title: string;
  _id: string;
};

type ITagObj = {
  key: string;
  value: string;
  creatorId: string;
  title: string;
  _id: string;
};

interface Props {
  type?: 'single' | 'multiple';
  mangoes: IMango[] | IFilterObj[] | ITagObj[];
  withoutFilterMangoList?: IMango[] | IFilterObj[] | ITagObj[];
  show: boolean;
  closeModal: () => void;
  selectedMangoes: IMango[] | IFilterObj[] | ITagObj[];
  handleSave: (
    mangoes: IMango[] | IFilterObj[] | ITagObj[],
    closeModel?: boolean,
  ) => void;
  customTitle?: string;
  customDescription?: string;
  noMangoComponent?: React.ReactNode;
  customHeaderTitle?: string;
  setSearchQuery?: React.Dispatch<React.SetStateAction<string>>;
  searchQuery?: string;
  showSearchBar?: boolean;
  showSelectAllAndClearSection?: boolean;
}

const MangoSelectionModal: React.FC<Props> = ({
  show,
  closeModal,
  withoutFilterMangoList,
  mangoes,
  selectedMangoes,
  handleSave,
  type = 'multiple',
  customTitle,
  customDescription,
  noMangoComponent,
  customHeaderTitle,
  setSearchQuery,
  searchQuery,
  showSearchBar = false,
  showSelectAllAndClearSection = false,
}) => {
  const {
    hostMetadata: { offeringTitle, offeringTitles },
  } = useAppSelector((state) => state.app);

  const { colors } = useTheme();

  const [selected, setSelected] = useState<string[]>([]);

  const isSelectAll = useMemo(() => {
    if (Boolean(searchQuery)) return false;
    return selected?.length === mangoes?.length;
  }, [mangoes?.length, searchQuery, selected?.length]);

  function toggle(val: string) {
    if (type === 'single') {
      if (selected.indexOf(val) !== -1) {
        setSelected([]);
      } else {
        setSelected([val]);
      }
    } else {
      if (selected.indexOf(val) !== -1) {
        setSelected((prev) => [...prev].filter((x) => x !== val));
      } else {
        setSelected((prev) => [...prev, val]);
      }
    }
  }

  const handleSelectAll = () => {
    if (isSelectAll) {
      handleSave([], true);
    } else {
      handleSave(mangoes, true);
    }
  };

  const handleClearSection = () => {
    handleSave([], true);
  };

  useEffect(() => {
    setSelected(selectedMangoes.map((mango) => mango._id) || []);
  }, [selectedMangoes]);

  return (
    <BottomSheet
      open={show}
      onDismiss={() => {
        setSelected(selectedMangoes.map((mango) => mango._id) || []);
        closeModal();
      }}
      className="mango__selection__modal"
      header={
        <Header
          title={customHeaderTitle || `Select ${offeringTitle}`}
          canGoBack={false}
          noBorder
          actionItems={[
            <HeaderTextAction
              text="Done"
              onClick={() =>
                handleSave(
                  selected
                    ? (withoutFilterMangoList || mangoes).filter(
                        (mango) => selected.indexOf(mango._id) !== -1,
                      )
                    : [],
                )
              }
            />,
          ]}
        />
      }>
      <div className="mango__selection__modal__content__wrapper">
        <Typography.Title level={5} className="mango__selection__modal__title">
          {customTitle || 'Who can see your post?'}
        </Typography.Title>
        <Typography.Paragraph className="mango__selection__modal__description">
          {customDescription ||
            `Choose ${offeringTitle} in which you want to publish your post`}
        </Typography.Paragraph>
        {showSearchBar ? (
          <SearchBar
            onDebounce={(value) => {
              if (setSearchQuery) {
                setSearchQuery(value);
              }
            }}
            placeholder="Search"
            style={{
              width: '100%',
              borderColor: colors.BORDER,
              padding: 0,
            }}
          />
        ) : null}
        {showSelectAllAndClearSection ? (
          <div className="mango_select_all_wrapper">
            {Boolean(searchQuery) ? (
              <div />
            ) : (
              <button
                className={`mango_select_all ${
                  isSelectAll ? 'selected_all_mango' : ''
                }`}
                onClick={handleSelectAll}>
                <FaCheckSquare /> Select All
              </button>
            )}
            <button className="mango_select_all" onClick={handleClearSection}>
              <RiDeleteBin2Fill />
              Clear Selection
            </button>
          </div>
        ) : null}
        {mangoes?.length > 0 ? (
          <div className="mango__selection__modal__content">
            {[...mangoes].map((item) => (
              <MangoSelectorCard
                key={item._id}
                name={item.title}
                onClick={() => {
                  toggle(item._id);
                }}
                selected={selected.indexOf(item._id) !== -1}
              />
            ))}
          </div>
        ) : (
          noMangoComponent || (
            <NoDataFound title={`No ${offeringTitles} found`} />
          )
        )}
      </div>
    </BottomSheet>
  );
};

export default React.memo(MangoSelectionModal);
