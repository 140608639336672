import './CommentInput.scss';

import React, { memo, useEffect, useRef, useState } from 'react';

import { Avatar, Input, message } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import TMIcon from 'tm-icons-library';

import { useTheme } from '../../context/ThemeProvider';
import { uploadFiles, uploadFilesHandler } from '../../shared/file';
import { useAppSelector } from '../../shared/hooks';
import { ICommentSelected } from '../../types/commentTypes';
import CustomImagePreview from '../CustomImagePreview/CustomImagePreview';
import Loader from '../Loader';

const ICON_SIZE = 20;

interface Props {
  reply?: ICommentSelected;
  isSending: boolean;
  enableFileUpload?: boolean;
  enableMultipleFilesUpload?: boolean;
  onComment: (comment: string, files?: File[], onSuccess?: () => void) => void;
  disableOnlyFileUpload?: boolean;
  hideMention?: boolean;
  hidden?: boolean;
  className?: string;
}

const CommentInput: React.FC<Props> = ({
  reply,
  isSending,
  enableFileUpload = false,
  enableMultipleFilesUpload = false,
  onComment,
  disableOnlyFileUpload = false,
  hidden,
  hideMention,
  className = '',
}) => {
  const { colors: COLORS } = useTheme();

  const { profilePic } = useAppSelector((state) => state.user);

  const [text, setText] = useState('');
  const [files, setFiles] = useState<File[]>();

  const inputRef = useRef<TextAreaRef>(null);

  const getFileUrl = (file: File) => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return '';
  };

  // const isSavingDisabled = useMemo(() => {
  //   return !comment?.trim() && !file;
  // }, [comment, file]);

  const onReset = () => {
    setText('');
    setFiles(undefined);
  };

  const postComment = () => {
    const commentText = text.trim();

    if (
      !commentText &&
      (disableOnlyFileUpload || !files || files.length === 0)
    ) {
      if (!disableOnlyFileUpload) {
        message.warning(
          enableFileUpload
            ? 'Please add a comment or upload a file!'
            : 'Please add a comment!',
        );
      }
      return;
    }

    onComment(commentText, files, onReset);
  };

  useEffect(() => {
    if (hideMention) return;

    if (reply?.userId) {
      setText(`{{${reply?.userId}#${reply?.userName}}} `);
    } else {
      setText('');
    }
  }, [reply, hideMention]);

  if (hidden) return null;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}>
      {files && files?.length > 0 ? (
        <div className="file-previews-wrapper">
          {files.map((file, index) => (
            <CustomImagePreview
              key={file.name}
              fileUrl={getFileUrl(file)}
              wrapperStyle={{
                flexShrink: 0,
                borderRadius: 8,
                width: 'max-content',
              }}
              onRemove={() => setFiles(files.filter((_, i) => i !== index))}
            />
          ))}
        </div>
      ) : null}

      <div className={`input-wrapper ${className}`}>
        <Avatar
          src={profilePic}
          alt="Author"
          size={36}
          className="input-wrapper__avatar"
          style={{
            flexShrink: 0,
          }}
        />

        <Input.TextArea
          className="input-wrapper__input"
          disabled={isSending}
          placeholder="Add a comment..."
          autoSize
          ref={inputRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              postComment();
            }
          }}
          rows={1}
          style={{
            width: '100%',
            resize: 'none',
            border: 'none',
            boxShadow: 'none',
          }}
        />

        <div className="input-wrapper__actions">
          {!enableFileUpload ? null : (
            // Only for WL platforms
            <TMIcon
              name="camera"
              size={ICON_SIZE}
              color={COLORS.ICON}
              className="input-wrapper__actions__icon-btn"
              onClick={
                isSending
                  ? undefined
                  : () =>
                      uploadFilesHandler({
                        type: 'image',

                        mode: enableMultipleFilesUpload ? 'multiple' : 'single',
                        onUpload: (newFiles, type) => {
                          uploadFiles({
                            files: newFiles,
                            type,
                            maxSizeUnit: 'MB',
                            maxSize: 1024, // 1GB
                            uploadCallback: (list) => {
                              setFiles(list);
                            },
                          });
                        },
                      })
              }
              style={isSending ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
            />
          )}

          {isSending ? (
            <Loader
              size="small"
              className="input-wrapper__actions__sending-loader"
            />
          ) : (
            <TMIcon
              name="send"
              size={ICON_SIZE}
              color={COLORS.ICON}
              className="input-wrapper__actions__icon-btn"
              onClick={postComment}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(CommentInput);
