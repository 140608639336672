import './styles.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Avatar, Badge, Button, Typography } from 'antd';
import { FaAngleDown } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import TMIcon from 'tm-icons-library';

import {
  postTypeList,
  resetAllFilters,
  setFilteredMangoes,
  setFilters,
  setPostTags,
  setSelected,
} from '../../context/activityReducer';
import { useTheme } from '../../context/ThemeProvider';
import { checkForPermissions } from '../../context/userReducer';
import ManageCreators from '../../screens/Feed/components/ManageCreators/ManageCreators';
import NoDataFound from '../../screens/Feed/components/NoDataFound/NoDataFound';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { IMango, ITagsBase } from '../../types/feedTypes';
import { ROUTES } from '../../types/routes';
import { Onboarding, UAMPermissions } from '../../types/userTypes';
import MangoSelectionModal from '../MangoSelectionModal/MangoSelectionModal';
import DynamicHeader from './DynamicHeader';

type ITagObj = {
  key: string;
  value: string;
  creatorId: string;
  title: string;
  _id: string;
};

type ITag = { key: string; value: string; creatorId: string };

interface Props {
  showWorkspace?: boolean;
  tags?: ITag[];
}

const FeedHeader: React.FC<Props> = ({ showWorkspace = false, tags }) => {
  const { colors } = useTheme();

  const { isCommunityEnabled, isTagMango, hostMetadata } = useAppSelector(
    (state) => state.app,
  );
  const userDetails = useAppSelector((state) => state.user);
  const { selected, subscriptions, filteredMangoes, filters, postTags } =
    useAppSelector((state) => state.activity);
  const dispatch = useAppDispatch();
  const [showManageCreators, setShowManageCreators] = useState(false);
  const [isMangoModalOpen, setIsMangoModalOpen] = useState(false);
  const [isPostTypeOpen, setIsPostTypeOpen] = useState(false);
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const getAvatar = useCallback(
    (url: string, index: number, name: string, s: boolean = false) => (
      <div
        key={index}
        className={`subscription__avatar ${s ? 'selected' : ''}`}
        style={{ marginLeft: index > 0 ? -12 : 0, zIndex: 4 - index }}>
        <div
          className="subscription__avatar__image"
          style={
            s
              ? {
                  borderWidth: 0,
                  width: 28,
                  height: 28,
                }
              : {}
          }>
          <Avatar src={url} alt="profile" />
        </div>
        {s && (
          <span className="subscription__avatar__text">
            {name ? name.split(' ')[0] : ''}
          </span>
        )}
      </div>
    ),
    [],
  );

  const getParticipantsGroup = useMemo(() => {
    let displayParticipants = [...subscriptions].slice(0, 3);
    if (selected && selected !== 'all' && subscriptions.length) {
      const newSubscriptions = [...subscriptions];
      const selectedSubscription = newSubscriptions.find(
        (item: any) => item.creatorId === selected,
      );
      if (selectedSubscription) {
        displayParticipants = [
          { ...selectedSubscription, selected: true },
          ...newSubscriptions.filter(
            (item: any) => item.creatorId !== selected,
          ),
        ].slice(0, 3);
      }
    }
    const participantCount = subscriptions.length;
    if (selected && selected !== 'all' && participantCount) {
      const participant = subscriptions.find((s) => s.creatorId === selected);
      return getAvatar(
        participant?.creatorId === userDetails.id
          ? userDetails.profilePic
          : participant?.profilePicUrl,
        0,
        participant?.creatorId === userDetails.id ? 'Me' : participant?.name,
        true,
      );
    }
    return (
      <>
        {displayParticipants.map((participant, index) =>
          getAvatar(
            participant?.creatorId === userDetails.id
              ? userDetails.profilePic
              : participant?.profilePicUrl,
            index,
            participant?.creatorId === userDetails.id
              ? 'Me'
              : participant?.name,
            participant?.selected,
          ),
        )}
        {participantCount - displayParticipants.length > 0 ? (
          <div className="subscription__extra-participant">
            <span className="subscription__extra-participant__text">
              +{participantCount - displayParticipants.length}
            </span>
          </div>
        ) : null}
      </>
    );
  }, [
    getAvatar,
    selected,
    subscriptions,
    userDetails.id,
    userDetails.profilePic,
  ]);

  const showAddIcon = useMemo(
    () =>
      Boolean(
        isTagMango
          ? userDetails.type === 'creator_completed' ||
              userDetails.type === 'dual'
          : userDetails.id === hostMetadata.creator._id,
      ) ||
      (userDetails.type === 'creator_restricted' &&
        checkForPermissions(
          [UAMPermissions.FEED_WRITE],
          userDetails.userAccessPermissions,
        )) ||
      (userDetails.type === 'fan_completed' &&
        isCommunityEnabled &&
        !userDetails.isBlockedFromCommunityEngagement),
    [
      hostMetadata.creator._id,
      isCommunityEnabled,
      isTagMango,
      userDetails.id,
      userDetails.isBlockedFromCommunityEngagement,
      userDetails.type,
      userDetails.userAccessPermissions,
    ],
  );

  const withoutFilterMangoList = useMemo(() => {
    const tempMangoList =
      userDetails?.type === Onboarding.FAN_COMPLETED
        ? userDetails?.subscribedMangoes
        : userDetails?.creatorMangoes;
    return tempMangoList;
  }, [
    userDetails?.creatorMangoes,
    userDetails?.subscribedMangoes,
    userDetails?.type,
  ]);

  const mangoList = useMemo(() => {
    const tempMangoList =
      userDetails?.type === Onboarding.FAN_COMPLETED
        ? userDetails?.subscribedMangoes
        : userDetails?.creatorMangoes;
    const newFilteredMangoList = [...filteredMangoes, ...tempMangoList].reduce(
      (acc: IMango[], eachMango) => {
        if (
          eachMango?.title &&
          eachMango?.title.toLowerCase().match(searchQuery.toLowerCase())
        ) {
          return [...acc, eachMango];
        }
        return acc;
      },
      [],
    );
    let mangoIdList: string[] = [];
    return newFilteredMangoList.reduce((acc: IMango[], eachMango: IMango) => {
      if (!mangoIdList.includes(eachMango?._id)) {
        mangoIdList = [...mangoIdList, eachMango?._id];
        return [...acc, eachMango];
      }
      return acc;
    }, []);
  }, [
    filteredMangoes,
    searchQuery,
    userDetails?.creatorMangoes,
    userDetails?.subscribedMangoes,
    userDetails?.type,
  ]);

  const withoutFilterTagList = useMemo(() => {
    const newFilteredTagList = (tags || []).map((eachTag) => {
      return { ...eachTag, _id: eachTag?.key, title: eachTag?.value };
    });
    return newFilteredTagList;
  }, [tags]);

  const filteredTagList = useMemo(() => {
    return postTags.map((eachTag) => ({
      key: eachTag?._id,
      value: eachTag?.tagName,
      creatorId: eachTag?.creator,
      title: eachTag?.tagName,
      _id: eachTag?._id,
    }));
  }, [postTags]);

  const tagList = useMemo(() => {
    const newFilteredTagList = [...filteredTagList, ...(tags || [])].reduce(
      (acc: ITagObj[], eachTag) => {
        if (
          eachTag.value &&
          eachTag?.value.toLowerCase().match(searchQuery.toLowerCase())
        ) {
          return [
            ...acc,
            { ...eachTag, _id: eachTag?.key, title: eachTag?.value },
          ];
        }
        return acc;
      },
      [],
    );

    let tagIdList: string[] = [];
    return newFilteredTagList.reduce((acc: ITagObj[], eachTagObj: ITagObj) => {
      if (!tagIdList.includes(eachTagObj?._id)) {
        tagIdList = [...tagIdList, eachTagObj?._id];
        return [...acc, eachTagObj];
      }
      return acc;
    }, []);
  }, [filteredTagList, searchQuery, tags]);

  useEffect(() => {
    return () => {
      dispatch(resetAllFilters());
    };
  }, [dispatch]);
  return (
    <>
      <DynamicHeader
        path={ROUTES.FEED}
        actionItems={[
          showAddIcon && (
            <Link to={ROUTES.CREATE_POST} key="create-post">
              <div className="header--activity__action">
                <TMIcon name="add" color={colors.ICON} />
              </div>
            </Link>
          ),
        ]}>
        {showWorkspace ? (
          <div className="subscription__container">
            <div className="subscription__avatar__wrapper">
              {subscriptions.length > 0
                ? getParticipantsGroup
                : getAvatar(userDetails.profilePic || '', 0, 'Me', true)}
            </div>
            <button
              className="subscription--manage__button"
              onClick={() => {
                setShowManageCreators(true);
              }}>
              <svg width="13.627" height="13.627" viewBox="0 0 13.627 13.627">
                <path
                  id="Path_1306"
                  data-name="Path 1306"
                  d="M14.827,8.11h.1a1.7,1.7,0,1,1,0,3.407h-.049a.377.377,0,0,0-.271.632l.034.034a1.7,1.7,0,1,1-2.41,2.41l-.03-.03a.377.377,0,0,0-.636.264v.1a1.7,1.7,0,1,1-3.407,0,.4.4,0,0,0-.275-.389.367.367,0,0,0-.4.069l-.034.034a1.7,1.7,0,1,1-2.41-2.41l.03-.03a.377.377,0,0,0-.264-.636H4.7a1.7,1.7,0,0,1,0-3.407.4.4,0,0,0,.389-.275.367.367,0,0,0-.069-.4l-.034-.034A1.7,1.7,0,1,1,7.4,5.034l.03.03a.369.369,0,0,0,.407.074l.082-.017A.368.368,0,0,0,8.11,4.8V4.7a1.7,1.7,0,0,1,3.407,0v.049a.371.371,0,0,0,.229.34.367.367,0,0,0,.4-.069l.034-.034a1.7,1.7,0,1,1,2.41,2.41l-.03.03a.369.369,0,0,0-.074.407l.017.082A.368.368,0,0,0,14.827,8.11Z"
                  transform="translate(-3 -3)"
                  fill="currentColor"
                  opacity="0.3"
                />
                <path
                  id="Path_1307"
                  data-name="Path 1307"
                  d="M11.271,13.542A2.271,2.271,0,1,0,9,11.271,2.271,2.271,0,0,0,11.271,13.542Z"
                  transform="translate(-4.458 -4.458)"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
              <span className="subscription--manage__text">
                Manage Creators
              </span>
            </button>
          </div>
        ) : null}
        <div className="tags-filter__container">
          <div className="tags-filter__text">
            <p>
              <Badge
                // dot
                // count={
                //   (filteredMangoes?.length || 0) +
                //   (filters?.length || 0) +
                //   (postTags.length + 0)
                // }
                overflowCount={9}
                size="small"
                style={{
                  backgroundColor: colors.PRIMARY,
                }}>
                Filters
              </Badge>
            </p>
          </div>
          <div className="tags-filter__wrapper">
            <div style={{ display: 'flex', margin: 6 }}>
              <div className="create-post-header-details-mango feed-filter-button-wrapper">
                <Button
                  className={`siteBtn create-post-header-details-mango-button feed-filter-button ${
                    filteredMangoes?.length ? 'applied_filter_button' : ''
                  }`}
                  onClick={() => setIsMangoModalOpen(true)}>
                  <Typography.Text
                    className={`button-text ${
                      filteredMangoes?.length ? 'applied_filter' : ''
                    }`}>
                    {hostMetadata?.offeringTitle.toTitleCase()}
                  </Typography.Text>
                  <FaAngleDown
                    className={`dropdown-symbol ${
                      filteredMangoes?.length ? 'applied_filter' : ''
                    }`}
                  />
                </Button>
              </div>
              <div className="create-post-header-details-mango feed-filter-button-wrapper">
                <Button
                  className={`siteBtn create-post-header-details-mango-button feed-filter-button ${
                    filters?.length ? 'applied_filter_button' : ''
                  }`}
                  onClick={() => setIsPostTypeOpen(true)}>
                  <Typography.Text
                    className={`button-text ${
                      filters?.length ? 'applied_filter' : ''
                    }`}>
                    {' '}
                    Post type{' '}
                  </Typography.Text>
                  <FaAngleDown
                    className={`dropdown-symbol ${
                      filters?.length ? 'applied_filter' : ''
                    }`}
                  />
                </Button>
              </div>
              <div className="create-post-header-details-mango feed-filter-button-wrapper">
                <Button
                  className={`siteBtn create-post-header-details-mango-button feed-filter-button ${
                    postTags?.length ? 'applied_filter_button' : ''
                  }`}
                  onClick={() => setIsTagOpen(true)}>
                  <Typography.Text
                    className={`button-text ${
                      postTags?.length ? 'applied_filter' : ''
                    }`}>
                    Tag{' '}
                  </Typography.Text>
                  <FaAngleDown
                    className={`dropdown-symbol ${
                      postTags?.length ? 'applied_filter' : ''
                    }`}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <MangoSelectionModal
          withoutFilterMangoList={withoutFilterMangoList}
          mangoes={mangoList}
          selectedMangoes={filteredMangoes}
          handleSave={(m, closeModel = false) => {
            setIsMangoModalOpen(closeModel);
            dispatch(setFilteredMangoes(m));
            if (closeModel) {
              setSearchQuery('');
            }
          }}
          customHeaderTitle={`Select ${hostMetadata?.offeringTitles}`}
          customTitle={`Select ${hostMetadata?.offeringTitles}`}
          customDescription={
            'Choose the services for which you want to see the posts'
          }
          show={isMangoModalOpen}
          closeModal={() => setIsMangoModalOpen(false)}
          noMangoComponent={
            <NoDataFound title={`No ${hostMetadata?.offeringTitles} found`} />
          }
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          showSearchBar
          showSelectAllAndClearSection
        />

        <MangoSelectionModal
          mangoes={postTypeList}
          selectedMangoes={filters}
          handleSave={(m) => {
            setIsPostTypeOpen(false);
            dispatch(setFilters(m));
          }}
          customHeaderTitle={`Select type`}
          customTitle={`Select post type`}
          customDescription={'Choose the type of post which you want to see '}
          show={isPostTypeOpen}
          closeModal={() => setIsPostTypeOpen(false)}
        />
        <MangoSelectionModal
          withoutFilterMangoList={withoutFilterTagList}
          mangoes={tagList as ITagObj[]}
          selectedMangoes={filteredTagList}
          handleSave={(m, closeModel = false) => {
            setIsTagOpen(false);
            if (closeModel) {
              setSearchQuery('');
            }
            dispatch(
              setPostTags(
                (m as ITagObj[]).map((eachTag) => ({
                  _id: eachTag.key,
                  tagName: eachTag.value,
                  creator: eachTag?.creatorId,
                })) as ITagsBase[],
              ),
            );
          }}
          customTitle={`Select tag`}
          customHeaderTitle={`Select tag`}
          customDescription={
            'Choose the tags for which you want to see the posts'
          }
          show={isTagOpen}
          closeModal={() => setIsTagOpen(false)}
          noMangoComponent={<NoDataFound title={`No tags found`} />}
          showSearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
        />
        {/* {tags && tags.length > 0 ? (
          <div className="tags-filter__container">
            <div className="tags-filter__text">
              <p>
                <Badge
                  dot
                  count={
                    tags.filter((tag) =>
                      postTags.map((t) => t._id).includes(tag.key),
                    ).length
                  }
                  overflowCount={9}
                  size="small"
                  style={{
                    backgroundColor: colors.PRIMARY,
                    // color: colors.BACKGROUND,
                    // marginLeft: 6,
                  }}>
                  Tags
                </Badge>
              </p>
            </div>
            <div className="tags-filter__wrapper">
              {tags
                .map(({ key, ...rest }) => ({
                  key,
                  ...rest,
                  isSelected: postTags.map((t) => t._id).includes(key),
                }))
                .map(({ key, value, creatorId, isSelected }) => (
                  <div
                    key={key}
                    onClick={() => {
                      if (isSelected) {
                        dispatch(
                          setPostTags([
                            ...postTags.filter((t) => t._id !== key),
                          ]),
                        );
                      } else {
                        dispatch(
                          setPostTags([
                            ...postTags,
                            {
                              _id: key,
                              tagName: value,
                              creator: creatorId,
                            },
                          ]),
                        );
                      }
                    }}
                    className={`tags-filter__tag ${
                      isSelected ? 'active' : ''
                    }`}>
                    <Typography.Text>{value}</Typography.Text>
                  </div>
                ))}
            </div>
          </div>
        ) : null} */}
      </DynamicHeader>

      <ManageCreators
        showModal={showManageCreators}
        closeModal={() => setShowManageCreators(false)}
        subscriptions={subscriptions.filter((sub) => sub.creatorId)}
        userDetails={userDetails}
        selected={selected}
        setSelected={(val) => {
          dispatch(setSelected(val));
          setShowManageCreators(false);
        }}
      />
    </>
  );
};

export default React.memo(FeedHeader);
