import React, { useState } from 'react';

import { message } from 'antd';

import CourseAPI from '../../../api/CourseAPI';
import CommentInput from '../../../components/CommentInput/CommentInput';
import showAppError from '../../../shared/error';
import { ICreateCourseCommentRequest } from '../../../types/courseTypes';
import { IQNAReply } from '../../../types/qnaTypes';

interface Props {
  type: 'comment' | 'qna';
  chapterId: string;
  qnaId: string;
  noMedia?: boolean;
  onAddReply: (arg0: IQNAReply) => void;
}

const MAX_FILES_ALLOWED = 5;

const InputBox: React.FC<Props> = ({ type, chapterId, qnaId, onAddReply }) => {
  const [isSaving, setIsSaving] = useState(false);

  const onSend = async (
    text: string,
    files?: File[],
    onSuccess?: () => void,
  ) => {
    if (files && files.length > MAX_FILES_ALLOWED) {
      message.warning('You can upload maximum 5 files');
      return;
    }

    setIsSaving(true);

    const reqBody: ICreateCourseCommentRequest = {
      description: text,
      ...(type === 'qna' && files && files.length > 0 && { files }),
      type,
    };

    try {
      const resp = await CourseAPI.createReply(chapterId, qnaId, reqBody);
      if (resp.status === 200) {
        message.success('Reply added successfully');
        onAddReply({
          ...resp.data.result,
          // userId: userDetails.id || '',
          // userName: userDetails.name || '',
          // imgUrl: userDetails.profilePic || '',
        } as unknown as IQNAReply);
        onSuccess?.();
      } else {
        showAppError(resp.data);
      }
    } catch (error) {
      showAppError(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="replyInputBox">
      <CommentInput
        className="commentInputBox__input-item"
        onComment={onSend}
        enableFileUpload={type === 'qna'}
        enableMultipleFilesUpload
        isSending={isSaving}
        hideMention
      />
    </div>
  );
};

export default React.memo(InputBox);
