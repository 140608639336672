import {
  message,
  Typography,
} from 'antd';
import heic2any from 'heic2any';
import linkifyHtml from 'linkify-html';
import moment from 'moment';
import pluralize from 'pluralize';
import Values from 'values.js';

import API from '../api';
import { setToken } from '../context/appReducer';
import { store } from '../context/store';
import { DRMTypes } from '../types/baseTypes';
import { IWebinar } from '../types/feedTypes';
import { TitleConfig } from '../types/HostMetadata';
import {
  ROUTES,
  UAM_PATH_ACCESS_MAP,
} from '../types/routes';
import {
  OnboardingStatus,
  UAMPermissions,
} from '../types/userTypes';
import {
  REFRESH_TOKEN_KEY,
  TOKEN_KEY,
} from './data';
import { CustomNavigateFunction } from './hooks';
import { ICOLORS } from './Styles';

// plural rule for mango => mangoes
pluralize.addPluralRule(/mango$/i, 'mangoes');

export const setLocalData = (token: string | null) => {
  if (token) localStorage.setItem(TOKEN_KEY, token);
  else {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
  API.setAuthToken(token);
  store.dispatch(setToken(token));
};
export const getLocalData = () => localStorage.getItem(TOKEN_KEY);

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const rgxNums = /^[0-9]+$/;
export const rgx10digit = /^[0-9]{10}$/;
export const rgx15digit = /^[0-9]{1,15}$/;
export const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

export const handleHttpError = (error: any) => {
  const { response } = error;
  if (response && response.data.message) {
    message.error(response.data.message);
  } else if (error.message && typeof error.message === 'string') {
    message.error(error.message);
  } else {
    message.error('Something went wrong');
  }
  return Promise.reject(error);
};

export const navigateToOnboardingScreen = async (
  onBoardingStatus: OnboardingStatus | null,
  navigate: CustomNavigateFunction,
  reset: boolean = false,
  userAccessPermissions?: UAMPermissions[],
) => {
  const {
    customRouter: { homeRoutes, initialRoutes },
    mDeeplinkUrl,
  } = store.getState().app;

  const defaultRoute = initialRoutes?.default;
  let route = defaultRoute;

  // initial redirection for UAM
  // Steps:
  // check if feed is present in homeRoutes, if yes, then feed is initial route.
  // check if default route present, if yes, then check access to default route, if yes, then default route is initial route.
  // check access to any other route in homeRoutes, if yes, then that route is initial route.
  // no access to any of the above, then access denied route is the initial route
  if (onBoardingStatus === 'creator_restricted') {
    let routeToOpen;

    // check if Feed is visible
    const isFeedVisible =
      homeRoutes?.some((r) => r.name === ROUTES.FEED) &&
      userAccessPermissions?.includes(UAMPermissions.FEED_READ);

    if (isFeedVisible) {
      routeToOpen = ROUTES.FEED;
    }

    // check if UAM has access to default route
    const defaultRouteAccess =
      UAM_PATH_ACCESS_MAP?.[
        defaultRoute?.route as keyof typeof UAM_PATH_ACCESS_MAP
      ];

    if (
      defaultRouteAccess &&
      userAccessPermissions?.includes(defaultRouteAccess)
    ) {
      routeToOpen = defaultRoute?.route as ROUTES;
    } else {
      // check if UAM has access to any visible route
      const accessibleRoute = homeRoutes?.find((r) => {
        const routeAccess =
          UAM_PATH_ACCESS_MAP?.[
            r.config.path as keyof typeof UAM_PATH_ACCESS_MAP
          ];

        return routeAccess && userAccessPermissions?.includes(routeAccess);
      });

      if (accessibleRoute) {
        routeToOpen = accessibleRoute.name;
      }
    }

    navigate(routeToOpen || ROUTES.NO_ACCESS, {}, { reset });

    return;
  }

  if (onBoardingStatus) {
    route = initialRoutes?.[onBoardingStatus] || defaultRoute;
  }

  if (route && !route.isTMProject) {
    window.open(`${mDeeplinkUrl}web${route.route}`, '_self');
  } else {
    const path = route?.route || ROUTES.HOME;

    if (onBoardingStatus === 'started') {
      navigate(ROUTES.REGISTER, {}, { reset });
    } else if (onBoardingStatus) {
      navigate(path, {}, { reset });
    } else {
      const currToken = getLocalData();

      if (currToken) {
        navigate(path, {}, { reset });
      } else {
        localStorage.clear();
        navigate(ROUTES.LOGIN, {}, { reset });
      }
    }
  }
};

/** Used to extract specific keys from an object, if exclude is true, then all keys except those keys will be returned as a new object */
export const extract = function <T extends string | number | symbol = string>(
  obj: Record<T, any>,
  keys: T[],
  exclude: boolean = false,
) {
  var clone = Object.assign({}, obj);

  if (keys && keys instanceof Array) {
    for (var k in clone) {
      var hasKey = keys.indexOf(k) >= 0;
      if ((!hasKey && !exclude) || (hasKey && exclude)) {
        delete clone[k];
      }
    }
  }

  return clone;
};

export const copyToClipboard = (text: string, msg?: string) => {
  const successMsg = msg || 'Copied successfully';
  const errorMsg = 'Oops, unable to copy';

  if (navigator.clipboard) {
    navigator.clipboard.writeText(text.toString()).then(
      () => {
        message.success(successMsg);
      },
      (err) => {
        console.log(err);
        message.error(errorMsg);
      },
    );

    return;
  }

  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      message.success(successMsg);
    } else {
      message.error(errorMsg);
    }
  } catch (err) {
    console.log(err);
    message.error(errorMsg);
  }

  document.body.removeChild(textArea);
};

export const handleShareLink = (
  pathname: string,
  shortUrl: boolean = false,
  dontCopy: boolean = false,
) => {
  const { shortUrl: shortDeeplinkUrl, deeplinkUrl } = store.getState().app;
  let updatedPathname;
  let link;
  if (shortUrl) {
    updatedPathname = pathname;
    link = `${shortDeeplinkUrl}${updatedPathname}`;
  } else {
    updatedPathname = pathname[0] === '/' ? pathname.slice(1) : pathname;
    link = `${deeplinkUrl}${updatedPathname}`;
  }
  if (dontCopy) return link;
  copyToClipboard(link);
  return link;
};

export const nFormat = (num: number, startLimit = 999, digits = 0): string => {
  if (!num) {
    return '0';
  }
  if (num <= startLimit) {
    return parseFloat(num.toFixed(digits)).toString();
  }
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => num >= i.value);
  return item
    ? parseFloat((num / item.value).toFixed(digits).replace(rx, '$1')) +
        item.symbol
    : '0';
};

/* eslint-disable no-param-reassign */
/**
 * Formats a number according to specified locale and format options.
 *
 * @param {number} num - The actual number to be formatted.
 * @param {string} format - Format of the number expected with comma, default is 'NUM_INR'.
 * @param {string} digits - SHORT - 3.4M, LONG - 3,400,000 | default is 'LONG'.
 * @param {number} startLimit - Numbers less than or equal to this value won't be formatted, default is 9999.
 * @returns {string | number} Formatted number with proper symbol indicating value.
 *
 * @example
 * newNumberFormat(earnings, 'NUM_INR')
 * // returns a number with Indian styled commas
 */
export const newNumberFormat = (
  num: number,
  format: 'NUM_INR' | 'NUM_US' | 'NUM_EUR' = 'NUM_INR',
  digits: 'LONG' | 'SHORT' = 'LONG',
  startLimit: number = 9999,
): string | number => {
  if (!num) return '0';

  if (!Number.isInteger(num)) {
    num = Number(parseFloat(num.toFixed(2)));
  }

  if (num <= startLimit) return num;

  const locale: Record<string, string> = {
    NUM_INR: 'en-IN',
    NUM_US: 'en-US',
    NUM_EUR: 'en-DE',
  };

  const options: Record<string, Intl.NumberFormatOptions> = {
    LONG: {
      notation: 'standard',
      maximumFractionDigits: 2,
    },
    SHORT: {
      notation: 'compact',
      maximumFractionDigits: 2,
    },
  };

  const numSystem = new Intl.NumberFormat(locale[format], options[digits]);

  return numSystem.format(parseFloat(num.toFixed(2)));
};

export const timeAgo = (time: any) => {
  // update locale string
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '%d seconds',
      ss: '%d seconds',
      m: '%d minute',
      mm: '%d minutes',
      h: '%d hour',
      hh: '%d hours',
      d: '%d day',
      dd: '%d days',
      w: '%d week',
      ww: '%d weeks',
      M: '%d month',
      MM: '%d months',
      y: '%d year',
      yy: '%d years',
    },
  });
  const timeDiff = moment(time).fromNow(true).split(' ');
  if (timeDiff[1] === 'month' || timeDiff[1] === 'months') {
    return `${parseInt(timeDiff[0], 10) * 4}w`;
  }
  return `${timeDiff[0]}${timeDiff[1].slice(0, 1)}`;
};

export const getReadableDateFormat = (date: string | Date) => {
  const dayMoment = moment(date).startOf('day');
  const dayDiff = moment().startOf('day').diff(dayMoment, 'day');
  if (dayDiff === 0) return 'Today';
  if (dayDiff === 1) return 'Yesterday';
  if (dayDiff < 6) return dayMoment.format('dddd').toTitleCase();
  return dayMoment.format('DD/MM/YYYY');
};

export const durationFormat = (seconds = 0) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;
  const hoursString = hours > 0 ? `${Math.floor(hours)}h ` : '';
  const minutesString = minutes > 0 ? `${Math.floor(minutes)}m ` : '';
  const secondsString = `${Math.floor(secondsLeft)}s`;
  return `${hoursString}${minutesString}${secondsString}`;
};

/** Returns the current browser and drmType */
export const checkBrowser = (): {
  browser: string;
  drmType: DRMTypes;
} => {
  const agent = navigator.userAgent.toLowerCase();
  let currBrowser = 'Chrome';
  let currDrmType: DRMTypes = 'Widevine';
  if (agent.indexOf('safari') > -1) {
    // Chrome or Safari
    if (agent.indexOf('opr') > -1) {
      // Opera
      currBrowser = 'Opera';
      currDrmType = 'Widevine';
    } else if (agent.indexOf('whale') > -1) {
      // Chrome
      currBrowser = 'Whale';
      currDrmType = 'Widevine';
    } else if (agent.indexOf('edg/') > -1 || agent.indexOf('Edge/') > -1) {
      // Chrome
      currBrowser = 'Edge';
      currDrmType = 'PlayReady';
    } else if (agent.indexOf('chrome') > -1) {
      // Chrome
      currBrowser = 'Chrome';
      currDrmType = 'Widevine';
    } else {
      // Safari
      currBrowser = 'Safari';
      currDrmType = 'FairPlay';
    }
  } else if (agent.indexOf('firefox') > -1) {
    // Firefox
    currBrowser = 'firefox';
    currDrmType = 'Widevine';
  }

  return { browser: currBrowser, drmType: currDrmType };
};

// make base64 image form file object
export const getBase64 = (img: Blob, callback: (arg: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () =>
    callback(reader.result?.toString() || ''),
  );
  reader.readAsDataURL(img);
};

export const convertImage = (
  type: string,
  fileData: Blob,
  callBack: (arg1: any, arg2: string) => void,
  isCrop: boolean = false,
  rowFileData?: string | ArrayBuffer | null,
) => {
  if (type === 'heic') {
    getBase64(fileData, (imageUrl) => {
      fetch(imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          return heic2any({
            blob,
            toType: 'image/jpeg',
            quality: 0.9,
          });
        })
        .then((conversionResult) => {
          const url = URL.createObjectURL(conversionResult as Blob);
          let fileImage = conversionResult;
          // @ts-ignore
          fileImage.name = `${fileData?.name.split('.')[0]}.jpeg`;
          if (isCrop) {
            callBack(url, url);
          } else callBack(fileImage, url);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  } else {
    getBase64(fileData, (imageUrl) => {
      if (isCrop) {
        callBack(rowFileData, imageUrl);
      } else {
        callBack(fileData, imageUrl);
      }
    });
  }
};

export const getFileExtension = (fileName: string) => {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(fileName);
};

export const createGuid = (guidLength: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < guidLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getFileNameFromPath = (path: string) => {
  return path.replace(/^.*[\\\/]/, '');
};

type TagKeys = 'success' | 'warning' | 'danger' | 'info' | 'idle' | 'primary';

export const createTag = (
  tag: string,
  color: TagKeys,
  COLORS: ICOLORS,
  style?: React.CSSProperties,
) => {
  const tagColorMap = {
    success: COLORS.GREEN,
    info: COLORS.BLUE,
    warning: '#FFCA28',
    danger: COLORS.DANGER,
    idle: COLORS.ICON,
    primary: COLORS.PRIMARY,
  };
  return (
    <div
      key={`${tag}-${color}`}
      className="tag"
      style={{
        backgroundColor: tagColorMap[color],
        ...style,
      }}>
      <Typography.Text style={{ textTransform: 'uppercase', color: '#fff' }}>
        {tag}
      </Typography.Text>
    </div>
  );
};

declare global {
  interface String {
    /** Converts the string into title case i.e. only first letter will be capitalized. */
    toTitleCase(): string;
    hashCode(): number;
  }
}
String.prototype.hashCode = function () {
  let hash = 0;
  let i;
  let chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i += 1) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
String.prototype.toTitleCase = function () {
  return this.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
};

export const getQueryString = (data: Record<string, string>) => {
  const query = Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
  return `?${query}`;
};

export const hexToHSL = (H: string) => {
  // Convert hex to RGB first
  let r: any = 0;
  let g: any = 0;
  let b: any = 0;
  if (H.length === 4) {
    r = `0x${H[1]}${H[1]}`;
    g = `0x${H[2]}${H[2]}`;
    b = `0x${H[3]}${H[3]}`;
  } else if (H.length === 7) {
    r = `0x${H[1]}${H[2]}`;
    g = `0x${H[3]}${H[4]}`;
    b = `0x${H[5]}${H[6]}`;
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return `hsl(${h},${s}%,${95}%)`;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i))?.toFixed?.(dm))} ${sizes[i]}`;
};

/**
 * get the domain replaced based on the current domain
 * Implemented for cookie in content
 * @param rootUrl domain url
 * @param url content url
 * @returns
 */
export const getModifiedURL = (rootUrl: string, url?: string) => {
  // if (url?.includes('https://tagmango.com')) {
  //   return url?.replace(
  //     'https://tagmango.com/',
  //     `${rootUrl || 'https://tagmango.com/'}`,
  //   );
  // }
  // TODO: removed cookies for now
  return url;
};

/**
 * get modified content where links are replaced with anchor tags
 * @param originalContent content to be linkified
 * @returns linkified content
 */
export const linkifyContent = (originalContent: string) =>
  linkifyHtml(originalContent, {
    defaultProtocol: 'https',
  });

/**
 * This function is used to get the resource name from the url
 * @param str url
 * @returns name of the resource
 */
export const getResourceName = (str: string) => {
  // return url.split('/').pop();
  // name-hash
  const fileName = str.split('/').pop();
  const extension = fileName?.split('.').pop();
  // remove hash
  const name = fileName?.split('-').slice(0, -1).join('-');
  return name + '.' + extension;
};

export const checkVideoFileValidation = (fileData: File) => {
  const type = fileData.type
    ? fileData.type
    : getFileExtension(fileData.name)?.[1].toLowerCase();

  let isVideo =
    type === 'video/mp4' ||
    type === 'mp4' ||
    type === 'video/webm' ||
    type === 'webm' ||
    type === 'video/quicktime' ||
    type === 'quicktime' ||
    type === 'video/mov' ||
    type === 'mov' ||
    type === 'video/mkv' ||
    type === 'mkv';

  if (type === 'video/mp4' || type === 'mp4') {
    const extention = getFileExtension(fileData.name)?.[1].toLowerCase();
    if (extention === 'm4v') {
      isVideo = false;
    }
  }
  return isVideo;
};

// export const hashCode = (s: string) =>
//   s.split('').reduce((acc, current) => acc + current.charCodeAt(0), 0);

// /**
//  * Generate a unique id for each player instance
//  * @param mediaId media id
//  * @returns {number} player id
//  * */
// export const getPlayerId = (mediaId: string) => {
//   return hashCode(mediaId) + Math.floor(Math.random() * 100000);
// };

export const formatName = (
  name: string,
  data?: {
    nameType?: 'first' | 'last' | 'full';
    caseType?: 'upper' | 'title';
    separator?: string;
  },
): string => {
  const { nameType, caseType, separator } = data ?? {};

  const [firstName, lastName] = name.split(separator ?? ' ');

  let finalName = '';

  if (nameType) {
    switch (nameType) {
      case 'first':
        finalName = firstName;
        break;
      case 'last':
        finalName = lastName;
        break;
      case 'full':
        finalName = name;
        break;
      default:
        break;
    }
  }

  if (caseType) {
    switch (caseType) {
      case 'upper':
        return finalName.toUpperCase();
      case 'title':
        return finalName.toTitleCase();
      default:
        break;
    }
  }

  return finalName;
};

/**
 * @param color Hex value format: #ffffff
 * @param weight darken value between 0 and 100
 * @returns Hex value
 */
export const shadeColor = (color: string, weight: number): string => {
  return `#${new Values(color).shade(weight).hex}`;
};

/**
 * @param color Hex value format: #ffffff
 * @param weight lighten value between 0 and 100
 * @returns Hex value
 */
export const tintColor = (color: string, weight: number): string => {
  return `#${new Values(color).tint(weight).hex}`;
};

export const getMeetingLink = (videoCall: IWebinar) => {
  if (!videoCall) return;

  if (videoCall?.platform === 'custom') return videoCall?.meetingUrl;

  return `${window.location.origin}/web${ROUTES.WEBINAR_METADATA_VIEW.replace(
    ':webinarId',
    videoCall._id,
  )}`;
};

export const objToQueryParams = (data: Record<string, string>) => {
  return Object.entries(data)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
};

/**
 * to shorten the text upto specific characters limit and add ... at the end (if overflow is true)
 * @param {string} text - the text to be shorten
 * @param {number} limit - the limit upto which the text should be shorten
 * @param {boolean} overflow - if true then add ... at the end of the shorten text
 * @returns {string} - the shorten text
 * @example
 * shortenText('This is a text', 5) // This ...
 * shortenText('This is a text', 100) // This is a text
 */
export const shortenText = (
  text: string,
  limit: number,
  overflow?: boolean,
) => {
  if (text?.length > limit) {
    return `${text?.substring(0, limit)}${overflow ? '...' : ''}`;
  }

  return text;
};

/**
 * Generates a regular expression pattern for a given number of digits.
 * @param {number} digitsLength - The desired number of digits.
 * @returns {RegExp} A regular expression pattern that matches strings with the specified number of digits.
 * @throws {Error} - Throws an error if digitsLength is not a positive integer.
 */
export const generateDigitsRegex = (digitsLength: number) => {
  // Check if digitsLength is a positive integer
  if (
    typeof digitsLength !== 'number' ||
    digitsLength <= 0 ||
    !Number.isInteger(digitsLength)
  ) {
    throw new Error('Number of digits must be a positive integer');
  }

  // Create a regular expression pattern using template literals
  const regexPattern = new RegExp(`^[0-9]{${digitsLength}}$`);

  // Return the generated regular expression pattern
  return regexPattern;
};

export const detectBrowser = (ua: string) => {
  let match;

  match = ua.match(/EdgA?\/([\d.]+)/);
  if (match) return { browser: 'Edge', version: match[1] };

  match = ua.match(/OPR\/([\d.]+)|Opera\/([\d.]+)/);
  if (match) return { browser: 'Opera', version: match[1] || match[2] };

  match = ua.match(/Chrome\/([\d.]+)/);
  if (match && !/Edg|OPR/.test(ua))
    return { browser: 'Chrome', version: match[1] };

  match = ua.match(/Safari\/([\d.]+)/);
  if (match && !/Chrome/.test(ua))
    return { browser: 'Safari', version: match[1] };

  match = ua.match(/Firefox\/([\d.]+)/);
  if (match) return { browser: 'Firefox', version: match[1] };

  match = ua.match(/MSIE ([\d.]+)|Trident\/.*rv:([\d.]+)/);
  if (match)
    return { browser: 'Internet Explorer', version: match[1] || match[2] };

  return { browser: 'Unknown Browser', version: 'N/A' };
};

// const irregularPlurals = new Set([
//   'children',
//   'feet',
//   'teeth',
//   'geese',
//   'mice',
//   'men',
//   'women',
//   'oxen',
//   'lice',
//   'cacti',
//   'radii',
//   'fungi',
//   'nuclei',
//   'syllabi',
//   'alumni',
//   'data',
//   'criteria',
//   'phenomena',
// ]);

// const uncountableNouns = new Set([
//   'information',
//   'equipment',
//   'furniture',
//   'money',
//   'rice',
//   'sheep',
//   'fish',
//   'deer',
//   'species',
//   'series',
//   'news',
// ]);

/**
 * Determines if a given word is in singular or plural form.
 * @param word - The word to check.
 * @param type - Either "singular" or "plural".
 * @returns {boolean} - True if the word is in the specified form.
 */
export const checkWordForm = (
  phrase: string,
  type: 'singular' | 'plural',
): boolean => {
  if (!phrase) return false;

  // const words = phrase.toLowerCase().split(' ');
  // const lastWord = words[words.length - 1];

  // if (irregularPlurals.has(lastWord) || uncountableNouns.has(lastWord)) {
  //   return true;
  // }

  // // Common plural endings
  // const pluralPatterns = [
  //   /s$/, // Most standard plurals (cats, dogs)
  //   /xes$/, // Boxes, foxes
  //   /ches$/, // Churches, benches
  //   /shes$/, // Dishes, wishes
  //   /oes$/, // Potatoes, heroes
  //   /ies$/, // Babies, cities
  //   /ves$/, // Wolves, knives
  //   /men$/, // Women, men (irregular)
  //   /ae$/, // Antennae, formulae (Latin origin)
  //   /i$/, // Cacti, fungi
  // ];

  // const isInPluralForm = pluralPatterns.some((pattern) =>
  //   pattern.test(lastWord),
  // );

  const isInPluralForm = pluralize.isPlural(phrase);

  return type === 'plural' ? isInPluralForm : !isInPluralForm;
};

type WordForm = 'singular' | 'plural';

/**
 * Converts a word between singular and plural forms.
 * @param word - The word to convert.
 * @param targetForm - Either "singular" or "plural".
 * @returns {string} - The converted word.
 */
export const convertToSingularOrPlural = (
  word: string,
  targetForm: WordForm,
): string => {
  if (!word) return word;

  const lowerCaseWord = word.toLowerCase();

  // Check if the noun is already in the target form
  if (checkWordForm(lowerCaseWord, targetForm)) return lowerCaseWord;

  return pluralize(word, targetForm === 'plural' ? 2 : 1);

  // const pluralRules = new Intl.PluralRules('en', { type: 'cardinal' });

  // if (targetForm === 'plural') {
  //   if (/[^aeiou]y$/.test(lowerCaseWord))
  //     return `${lowerCaseWord.slice(0, -1)}ies`;
  //   if (/(s|x|z|ch|sh)$/.test(lowerCaseWord)) return `${lowerCaseWord}es`;
  //   return pluralRules.select(2) === 'one'
  //     ? lowerCaseWord
  //     : `${lowerCaseWord}s`;
  // }

  // if (targetForm === 'singular') {
  //   if (/ies$/.test(lowerCaseWord)) return `${lowerCaseWord.slice(0, -3)}y`;
  //   if (/(ses|xes|zes|ches|shes)$/.test(lowerCaseWord))
  //     return lowerCaseWord.slice(0, -2);
  //   if (lowerCaseWord.endsWith('s')) return lowerCaseWord.slice(0, -1);
  // }

  // return lowerCaseWord;
};

/**
 * pluralizeNoun - Pluralize a noun based on common rules
 * @param {string} noun - The noun to pluralize
 * @returns {string} The pluralized noun
 * @example
 * console.log(pluralizeNoun('cat')); // Output: "cats"
 * console.log(pluralizeNoun('bus')); // Output: "buses"
 * console.log(pluralizeNoun('potato')); // Output: "potatoes"
 */
export const pluralizeNoun = (noun: string): string => {
  return convertToSingularOrPlural(noun, 'plural');
};

export const getSingularOrPlural = (val: number, text: string) =>
  val > 1 ? pluralizeNoun(text) : text;

export const scrollToElement = (data: { className?: string; id?: string }) => {
  const { className, id } = data;

  if (!className && !id) return;

  let element = document.querySelector(`.${className}`);

  if (id) {
    element = document.getElementById(id);
  }

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }
};

export const getArticle = (phrase: string): string => {
  if (!phrase) return '';

  const word = phrase.trim().toLowerCase();
  const firstChar = word[0];
  const firstWord = word.split(' ')[0]; // Handle multi-word cases

  // Words that start with a vowel but use "a" (e.g., "a university", "a European")
  const aExceptions = new Set([
    'university',
    'european',
    'one',
    'unit',
    'user',
    'unique',
  ]);

  // Words that start with a consonant but use "an" (e.g., "an hour", "an honest person")
  const anExceptions = new Set(['honest', 'hour', 'heir', 'honor']);

  if (anExceptions.has(firstWord) || /^[aeiou]/.test(firstChar)) {
    return 'an';
  }

  if (aExceptions.has(firstWord) || /^[^aeiou]/.test(firstChar)) {
    return 'a';
  }

  return 'a'; // Default case
};

/**
 * Returns formatted text based on the provided text case.
 * @param word - The word to format.
 * @param textCase - The text case to format the word. (options: 'upper', 'lower', 'title', default: 'lower')
 * @returns The formatted word based on the text case.
 */
export const formatCase = (
  word: string,
  textCase: 'upper' | 'lower' | 'title' = 'lower',
): string => {
  switch (textCase) {
    case 'upper':
      return word.toUpperCase();
    case 'lower':
      return word.toLowerCase();
    case 'title':
      return word.toTitleCase();
    default:
      return word;
  }
};

/**
 * Returns the dynamic title based on the key provided.
 * @param key - The key to get the title for. (stored in the titleConfig accessible via store.getState().app)
 * @param textCase - The text case to format the title. (options: 'upper', 'lower', 'title', default: 'lower')
 * @param includeArticle - If true, includes the article (a/an) before the title. (default: false)
 * @returns The formatted title based on the key.
 */
export const dynamicItemTitle = <Key extends keyof TitleConfig>(
  key: Key,
  textCase: 'upper' | 'lower' | 'title' = 'lower',
  includeArticle: boolean = false,
): string => {
  const { titleConfig } = store.getState().app;

  return `${includeArticle ? `${getArticle(titleConfig[key])} ` : ''} ${formatCase(titleConfig[key], textCase)}`;
};
