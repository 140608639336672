import './styles.scss';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { message, Typography } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { nanoid } from '@reduxjs/toolkit';

import ChatAPI from '../../../../api/ChatAPI';
import config from '../../../../api/config';
import Assets from '../../../../assets';
import MessageHeader from '../../../../components/Header/MessageHeader';
import Loader from '../../../../components/Loader';
import {
  removeChatRoomData,
  setShowScrollDown,
} from '../../../../context/chatReducer';
import {
  addNewMessage,
  fetchMoreMessages,
  getMessagesAroundParentMessage,
  markMessagesAsRead,
} from '../../../../context/thunks/chatThunks';
import { BASENAME } from '../../../../shared/data';
import showAppError from '../../../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../../../shared/hooks';
import { CHAT_NAME_COLORS } from '../../../../shared/Styles';
import { getReadableDateFormat } from '../../../../shared/utils';
import {
  ExtraData,
  IMessage,
} from '../../../../types/chatModels/ChatMessagesResponse';
import { Participant } from '../../../../types/chatModels/MangoRoomListResponse';
import { IDMRoomDetails } from '../../../../types/messageTypes';
import { ROUTES } from '../../../../types/routes';
import BidirectionalScroll, {
  BidirectionalScrollRef,
} from '../../components/BidirectionalScroll';
import ChatInputDisabled from '../../components/ChatInputDisabled/ChatInputDisabled';
import DMChatScreenHeader from '../../components/DMChatScreenHeader/DMChatScreenHeader';
import MessageContents from '../../components/MessageContents/MessageContents';
import MessageOptions, {
  MessageOptionsRefProps,
} from '../../components/MessageOptions';
import ChatInputBox from '../ChatInputBox/ChatInputBox';
import DMChatInfo from '../DMChatInfo/DMChatInfo';

const DMChatScreen: React.FC = () => {
  const { roomId: rId, otherParticipantId } = useParams();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const { chatRoomData, showScrollDown } = useAppSelector(
    (state) => state.chat,
  );
  const {
    id: userId,
    disabledChat,
    ...userDetails
  } = useAppSelector((state) => state.user);
  const { token } = useAppSelector((state) => state.app);

  const [roomId, setRoomId] = useState<string | undefined>(rId);
  const [loadingRoom, setLoadingRoom] = useState<boolean>(true);
  const [roomDetails, setRoomDetails] = useState<IDMRoomDetails | null>(null);
  const [replyMessage, setReplyMessage] = useState<IMessage>();
  const [highlightedMessage, setHighlightedMessage] = useState<IMessage>();
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const colorMap = useRef<Record<string, string>>({});
  const scrollRef = useRef<BidirectionalScrollRef>(null);
  const loading = useRef<boolean>(false);
  const messageOptionsRef = useRef<MessageOptionsRefProps>(null);
  const [sender, setSender] = useState<Participant>();

  useEffect(() => {
    if (roomDetails?.participants?.length)
      setSender(roomDetails?.participants.find((p) => p._id !== userId));
  }, [roomDetails?.participants, userId]);

  const getRoomDetails = async (id: string) => {
    try {
      setLoadingRoom(true);
      const resp = await ChatAPI.getRoomDetails<IDMRoomDetails>(id);
      if (resp.status === 200) {
        setRoomDetails(resp.data.result);
        setLoadingRoom(false);
      } else {
        showAppError(resp.data);
        navigate(ROUTES.NOTFOUND);
      }
    } catch (error) {
      showAppError(error);
      // navigate(ROUTES.NOTFOUND);
    }
  };

  const checkRoomExists = async () => {
    if (otherParticipantId) {
      try {
        const resp = await ChatAPI.getExistingRoom(otherParticipantId);
        if (resp.status === 200 && resp.data.result) {
          if (resp.data.result._id) {
            setRoomId(resp.data.result._id);
            window.history.replaceState(
              {},
              '',
              `${BASENAME}${ROUTES.MESSAGE_DM.replace(
                ':roomId',
                resp.data.result._id,
              )}`,
            );
          } else {
            setSender(resp.data.result.otherParticipant);
            setRoomDetails({
              _id: nanoid(12),
              participants: [
                {
                  _id: userId || '',
                  name: userDetails.name || '',
                  profilePicUrl: userDetails.profilePic || '',
                },
                resp.data.result.otherParticipant,
              ],
              roomType: 'dm',
              blockedParticipants: [],
              enableSubscriberMessaging: true,
              peerConversation: true,
              lastMessageTime: new Date(),
              lastMessage: [''],
              participantCount: 2,
            });
            setLoadingRoom(false);
          }
        } else {
          showAppError(resp.data);
        }
      } catch (error) {
        showAppError(error);
      }
    }
  };

  useEffect(() => {
    if (roomId) getRoomDetails(roomId);
    else if (otherParticipantId) {
      checkRoomExists();
    } else {
      message.error('Room Id not found');
      navigate(ROUTES.NOTFOUND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    if (roomDetails && roomId && !loading.current) {
      loading.current = true;
      dispatch(
        fetchMoreMessages(roomId, 'up', () => {
          loading.current = false;
          scrollRef.current?.scrollToBottom();
        }),
      );
      dispatch(markMessagesAsRead(roomId, 'dm'));
    }
    return () => {
      dispatch(removeChatRoomData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomDetails]);

  const getNameColor = useCallback(
    (senderId: string) => {
      if (!colorMap.current[senderId]) {
        const color =
          CHAT_NAME_COLORS[Math.floor(Math.random() * CHAT_NAME_COLORS.length)];
        colorMap.current = {
          ...colorMap.current,
          [senderId]: color,
        };
        return color;
      }
      return colorMap.current[senderId];
    },
    [colorMap],
  );

  const getMessagesAround = useCallback(
    (messageId: string) => {
      if (roomId) dispatch(getMessagesAroundParentMessage(messageId, roomId));
    },
    [dispatch, roomId],
  );

  const onClickReply = useCallback(
    (parentMessageId: string) => {
      const index = chatRoomData.messages.findIndex(
        (msg) => msg._id === parentMessageId,
      );
      if (index !== -1) {
        // scroll to item
        scrollRef.current?.scrollToItem(parentMessageId);
      } else {
        // item needs to be loaded from api
        getMessagesAround(parentMessageId);
      }
    },
    [chatRoomData.messages, getMessagesAround],
  );

  const listData = useMemo(() => {
    const listItems: {
      type: 'message' | 'date' | 'new-participant';
      message?: IMessage;
      date?: Date;
      participantDetails?: { participantName: string; participantId: string };
    }[] = [];
    chatRoomData.messages.forEach((item, index) => {
      listItems.unshift({ type: 'message', message: item });
      if (index === chatRoomData.messages.length - 1) {
        listItems.unshift({ type: 'date', date: new Date(item.createdAt) });
      } else if (
        !moment(item.createdAt).isSame(
          chatRoomData.messages[index + 1].createdAt,
          'day',
        )
      ) {
        listItems.unshift({ type: 'date', date: new Date(item.createdAt) });
      }
    });
    // listItemLength.current = listItems.length;
    return listItems;
  }, [chatRoomData.messages]);

  const onStartReached = () => {
    if (chatRoomData.hasMore.previous && !loading.current && roomId) {
      loading.current = true;
      dispatch(
        fetchMoreMessages(roomId, 'up', () => {
          loading.current = false;
        }),
      );
    }
  };

  const onEndReached = () => {
    if (chatRoomData.hasMore.next && !loading.current && roomId) {
      loading.current = true;
      dispatch(
        fetchMoreMessages(roomId, 'down', () => {
          loading.current = false;
        }),
      );
    }
  };

  const downloadMedia = async ({
    contentUrl,
    fileName,
    extraData,
  }: {
    contentUrl: string | undefined;
    fileName: string | undefined;
    extraData?: ExtraData;
  }) => {
    try {
      const link = document.createElement('a');
      link.href = `${
        config.API_ENDPOINT
      }/download-for-chat?url=${contentUrl}&name=${`${fileName}.${extraData?.ext}`}&Authorization=${token}`;
      link.setAttribute('download', fileName || ''); // or any other extension
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        link.parentNode?.removeChild(link);
      }, 200);
    } catch (err) {
      console.log(err);
    }
  };

  const selectMessage = (item: IMessage) => {
    setHighlightedMessage(item);
    setTimeout(() => {
      messageOptionsRef.current?.showMenu();
    }, 100);
  };

  const checkForRoomBlock = useMemo((): string | null => {
    // case 1: Other user blocked DM messages
    if (!roomDetails || !sender) return null;
    if (sender.disabledChat) {
      return 'This person disabled DM messaging.';
    }

    // case 2: This user has globally blocked DM messages
    if (disabledChat) {
      return 'You disabled DM messaging.';
    }

    // case 3: Other user has blocked this particular room
    if (roomDetails.blockedParticipants?.includes(userId || '')) {
      return 'This person blocked you.';
    }

    // case 4: This user has blocked the other user
    if (roomDetails.blockedParticipants?.includes(sender?._id)) {
      return 'You blocked this person.';
    }

    return null;
  }, [disabledChat, roomDetails, sender, userId]);

  const scrollToBottom = () => {
    if (roomId) {
      if (chatRoomData.hasMore.next) {
        dispatch(removeChatRoomData());
        dispatch(
          fetchMoreMessages(roomId, 'up', () => {
            loading.current = false;
            scrollRef.current?.scrollToBottom();
          }),
        );
      } else {
        scrollRef.current?.scrollToBottom();
      }
    }
  };

  return (
    <>
      <div className="pageRoot">
        {loadingRoom && <Loader size="large" style={{ marginBlock: 24 }} />}

        {roomDetails && !loadingRoom && sender ? (
          <>
            <MessageHeader
              url={sender.profilePicUrl}
              title={sender.name}
              onClick={() => setShowInfoModal(true)}
            />
            <BidirectionalScroll
              ref={scrollRef}
              className="pageContent messageList"
              onShowScrollButton={(show) => {
                if (showScrollDown !== show) dispatch(setShowScrollDown(show));
              }}
              onStartReached={onStartReached}
              onEndReached={onEndReached}>
              <div className="messageList__container">
                <div
                  className="messageList__container__bg-img"
                  style={{
                    backgroundImage: `url(${Assets.images.WhatsAppLightBg})`,
                  }}
                />

                {(!roomId || !chatRoomData.hasMore.previous) && roomDetails ? (
                  <DMChatScreenHeader
                    senderId={sender._id}
                    picUrl={sender.profilePicUrl}
                    title={sender.name}
                  />
                ) : null}

                <div role="none" className="messageList__spacer" />
                {listData.map(({ date, message: item }) => (
                  <>
                    {item && (
                      <>
                        {item.senderId === userId ? (
                          <MessageContents
                            key={item._id}
                            message={item}
                            isSenderSelf
                            hideSeen={false}
                            sending={item.sending}
                            time={item.createdAt}
                            text={item.message}
                            type={item.messageType}
                            read={item.seenUsers.length === 2}
                            replyNameColor={
                              item.parentMessageSender
                                ? getNameColor(item.parentMessageSender._id)
                                : undefined
                            }
                            onClickReply={onClickReply}
                            id={item._id}
                            contentUrl={item.contentUrl}
                            compressedImageUrl={item.compressedImageUrl}
                            extraData={item.extraData}
                            fileName={item.fileName}
                            onOptionsClick={() => selectMessage(item)}
                            downloadMedia={() => {
                              downloadMedia({
                                contentUrl: item.contentUrl,
                                fileName: item.fileName,
                                extraData: item.extraData,
                              });
                            }}
                            isDeleted={item.isDeleted}
                            deletedByCreator={item.deletedByCreator}
                          />
                        ) : (
                          <MessageContents
                            key={item._id}
                            message={item}
                            isSenderSelf={false}
                            sender={{
                              isCreator:
                                roomDetails.isOtherParticipantCreator || false,
                              name: item.senderName,
                              profilePicUrl: item.senderProfilePic,
                            }}
                            time={item.createdAt}
                            type={item.messageType}
                            text={item.message}
                            nameColor={getNameColor(item.senderId)}
                            replyNameColor={
                              item.parentMessageSender
                                ? getNameColor(item.parentMessageSender._id)
                                : undefined
                            }
                            compressedImageUrl={item.compressedImageUrl}
                            contentUrl={item.contentUrl}
                            extraData={item.extraData}
                            fileName={item.fileName}
                            id={item._id}
                            onClickReply={onClickReply}
                            onOptionsClick={() => selectMessage(item)}
                            downloadMedia={() => {
                              downloadMedia({
                                contentUrl: item.contentUrl,
                                fileName: item.fileName,
                                extraData: item.extraData,
                              });
                            }}
                            isDeleted={item.isDeleted}
                            deletedByCreator={item.deletedByCreator}
                          />
                        )}
                      </>
                    )}
                    {date && (
                      <div
                        className="messageList__dateContainer"
                        key={date.getTime()}>
                        <Typography.Text className="messageList__date">
                          {getReadableDateFormat(date).toUpperCase()}
                        </Typography.Text>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </BidirectionalScroll>
            {roomDetails ? (
              checkForRoomBlock ? (
                <ChatInputDisabled title={checkForRoomBlock} />
              ) : (
                <ChatInputBox
                  onCloseReply={() => setReplyMessage(undefined)}
                  replyTo={replyMessage}
                  roomId={roomId}
                  onNewRoomCreated={(r) => {
                    setRoomId(r);
                    window.history.replaceState(
                      {},
                      '',
                      `${BASENAME}${ROUTES.MESSAGE_DM.replace(':roomId', r)}`,
                    );
                  }}
                  onMessageSent={(m, type) => {
                    dispatch(addNewMessage(m, type));
                    setTimeout(() => {
                      scrollRef.current?.scrollToBottom();
                    }, 500);
                  }}
                  otherParticipantId={otherParticipantId}
                  replyNameColor={
                    replyMessage
                      ? getNameColor(replyMessage.senderId)
                      : undefined
                  }
                  showScrollDown={showScrollDown}
                  scrollToBottom={scrollToBottom}
                />
              )
            ) : null}
          </>
        ) : null}
      </div>
      {roomDetails && sender && (
        <DMChatInfo
          sender={sender}
          showModal={showInfoModal}
          closeModal={() => setShowInfoModal(false)}
          isBlocked={roomDetails.blockedParticipants?.includes(sender._id)}
          roomId={roomDetails._id}
          updateBlockedParticipants={(bP) => {
            setRoomDetails({
              ...roomDetails,
              blockedParticipants: bP,
            });
          }}
          isNotificationMuted={roomDetails.isNotificationMuted}
          updateNotificationMuted={(v) => {
            setRoomDetails({
              ...roomDetails,
              isNotificationMuted: v,
            });
          }}
        />
      )}
      {roomDetails && highlightedMessage && (
        <MessageOptions
          ref={messageOptionsRef}
          message={highlightedMessage}
          onAddReply={() => {
            setReplyMessage(highlightedMessage);
          }}
          downloadMedia={() => {
            if (highlightedMessage)
              downloadMedia({
                contentUrl: highlightedMessage.contentUrl,
                fileName: highlightedMessage.fileName,
                extraData: highlightedMessage.extraData,
              });
          }}
          roomDetails={roomDetails}
          roomType="dm"
          isSenderSelf={highlightedMessage?.senderId === userId}
        />
      )}
    </>
  );
};

export default DMChatScreen;
