import './CustomImagePreview.scss';

import React, { memo, useEffect, useRef } from 'react';

import { Image } from 'antd';
import TMIcon from 'tm-icons-library';

import { useTheme } from '../../context/ThemeProvider';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  fileUrl: string;
  height?: number;
  wrapperStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  blurBgStyle?: React.CSSProperties;
  blurBgImageStyle?: React.CSSProperties;
  showBlurBgImage?: boolean;
  onRemove?: () => void;
}

const CustomImagePreview: React.FC<Props> = ({
  fileUrl,
  className = '',
  height = 60,
  wrapperStyle,
  imageStyle,
  blurBgStyle,
  blurBgImageStyle,
  showBlurBgImage = false,
  onRemove,
  ...restProps
}) => {
  const { colors: COLORS } = useTheme();

  const previewContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (previewContainerRef.current) {
      const imgElement = previewContainerRef.current.querySelector(
        '.custom-image-preview > img',
      ) as HTMLImageElement;
      const antImgElement = previewContainerRef.current.querySelector(
        '.custom-image-preview > .ant-image .ant-image-img',
      ) as HTMLImageElement;

      if (imgElement) {
        imgElement.style.height = `${height}px`;
      }

      if (antImgElement) {
        antImgElement.style.height = `${height}px`;
      }
    }
  }, [height]);

  return (
    <div
      ref={previewContainerRef}
      className={`custom-image-preview ${className}`}
      style={wrapperStyle}
      {...restProps}>
      <Image
        src={fileUrl}
        className="custom-image-preview__image"
        style={imageStyle}
      />

      {showBlurBgImage ? (
        <div className="custom-image-preview__blur-bg" style={blurBgStyle}>
          <img src={fileUrl} alt={fileUrl} style={blurBgImageStyle} />
        </div>
      ) : null}

      {onRemove ? (
        <span className="custom-image-preview__close-btn">
          <TMIcon
            name="close"
            size={12}
            color={COLORS.BACKGROUND}
            onClick={onRemove}
            style={{ flexShrink: 0, cursor: 'pointer' }}
          />
        </span>
      ) : null}
    </div>
  );
};

export default memo(CustomImagePreview);
